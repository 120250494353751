import { useMemo } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { getLeadList, deleteLeadsAndUpdatedLeadList, updateFullLeadInfo, getListOfTeamMembersAndCheckCookies, getUploadingSnLinkForUser, sendErrorNotification, stopWorkUploadSnLink, markLeadsAsRead, exportLeads,   } from '../../slices/tableLeadsSlice/thunks';
import { useAppDispatch } from '../typedHooks';
import { tableLeadsSliceActions } from '../../slices/tableLeadsSlice/tableLeadsSlice';

const rootActions = {
  ...tableLeadsSliceActions,
  getLeadList,
  deleteLeadsAndUpdatedLeadList,
  updateFullLeadInfo,
  getListOfTeamMembersAndCheckCookies,
  getUploadingSnLinkForUser,
  sendErrorNotification,
  stopWorkUploadSnLink,
  markLeadsAsRead,
  exportLeads
};

export const useTableLeadsActions = () => {
  const dispatch = useAppDispatch();
  return useMemo(() => bindActionCreators(rootActions, dispatch), [dispatch]);
};