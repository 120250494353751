import React from "react";
import { TooltipApp } from "../../tooltipApp/TooltipApp";
import styles from "../PagesTabs.module.scss";
import { Box } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";

type Props = {
  title: string;
  link: string;
  disabled: boolean;
}

export const SettingsTab = ({ title, link, disabled }: Props) => {
  const pathname = useLocation().pathname

  const activeTabStyle = pathname.startsWith(link.slice(0, 13))
    ? `${styles.pagesTabs__tab} ${styles.activeLink}` // Active tab styling
    : styles.pagesTabs__tab;

  if (disabled) {
    return (
      <TooltipApp title={
        <>
          <span className={styles.toolTipBody}>
            <img src='/tooltip_icon.svg' alt='' />
            coming soon
          </span>
        </>
      }>
        <Box className={`${styles.pagesTabs__tab} ${styles.pagesTabs__tab_disabled}`}>
          {title}
        </Box>
      </TooltipApp>
    )
  }

  return (
    <NavLink
      end
      to={link}
      className={activeTabStyle}
    >
      {title}
    </NavLink>
  )
}

