import { Box } from '@mui/material';
import React from 'react';
import scss from '../AutomationIndicator.module.scss'

type Props = {
  currentNumberOfReplyForToday: number;
  limitOfReplyForADay: number;
}

export const CommentsCounter = ({
  currentNumberOfReplyForToday,
  limitOfReplyForADay
}: Props) => {
  const commentsSend = limitOfReplyForADay - currentNumberOfReplyForToday;
  const viewedCommentSend = commentsSend >= 0 ? commentsSend : limitOfReplyForADay

  return (
    <Box className={scss.messageCounter}>
      <Box className={scss.messageCounter__commentsLeft}>
        {viewedCommentSend}
      </Box>
      <Box className={scss.messageCounter__title}>
        Comments sent &#x2022; day
      </Box>
    </Box>
  )
}

