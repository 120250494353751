import { Skeleton, TableBody, TableCell, TableRow } from '@mui/material';
import * as React from 'react';
import { useAdminPageData } from '../../../../utils/redux/storeHooks/dataHooks/useAdminPageData';
import { TableLoader } from '../../../../components/tableLoader/TableLoader';

const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const LoaderSkeleton = () => {
  const { isUsersLoading } = useAdminPageData()
  return (
    <TableBody sx={{ position: 'relative' }}>
      {isUsersLoading && <TableLoader />}
      {arr.map((elem) => (
        <TableRow key={elem} sx={{ maxHeight: '64px' }}>
          <TableCell sx={{ minWidth: 150, maxWidth: 150, padding: '0px 12px' }}>
            <Skeleton variant="rectangular" width='0' height={40} />
          </TableCell>
          <TableCell sx={{ minWidth: 50, maxWidth: 50, padding: '12px 12px' }}>
            <Skeleton variant="rectangular" width='0' height={40} />
          </TableCell>
          <TableCell sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }}>
            <Skeleton variant="rectangular" width='0' height={40} />
          </TableCell>
          <TableCell sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }}>
            <Skeleton variant="rectangular" width='0' height={40} />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  )
}