import { Box, Divider, LinearProgress, Typography } from '@mui/material';
import * as React from 'react';
import style from '../../botManagementPage.module.scss'
import { useBotManagementData } from '../../../../utils/redux/storeHooks/dataHooks/useBotManagementData';
import { useBotManagementActions } from '../../../../utils/redux/storeHooks/actionsHooks/botManagementActions';
import { useEffect } from 'react';
import { SnLinksCard } from './snLinksCard';
import { SnLinksHeader } from './snLinksHeader';


export const SnLinks = () => {
  const { snLinks, isSnLinksLoading } = useBotManagementData()
  const { getNeedAdminCheckSnlinks } = useBotManagementActions()

  useEffect(() => {
    getNeedAdminCheckSnlinks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isSnLinksLoading) {
    return (
      <Box className={style.botManagement__snLinks} boxShadow={2}>
        <Typography className={style.botManagement__mainTitle}>
          Uploaded SN links need to check
        </Typography>
        <LinearProgress sx={{ width: '100%' }} color='inherit' />
      </Box>
    )
  }

  return (
    <Box className={style.botManagement__snLinks} boxShadow={2}>
      <Typography className={style.botManagement__mainTitle}>
        Uploaded SN links need to check
      </Typography>
      <Divider sx={{ width: '100%' }} />
      <SnLinksHeader />
      <Divider sx={{ width: '100%' }} />
      <Box className={style.botManagement__snLinks_list}>
        {snLinks.map(link => {
          return (
            <SnLinksCard key={link.id} link={link} />
          )
        })}
      </Box>
    </Box>
  )
}

