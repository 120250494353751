import { styled, TextField } from "@mui/material";

export const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    height: '44px',
    borderRadius: '1px',
    fontFamily: 'Kanit',
    margin: 0,

    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(109, 48, 183, 1)',
      boxShadow: 'rgba(109, 48, 183, 1) 0px 1px 3px'
    },
    '& .MuiInputBase-input': {
      caretColor: 'rgba(109, 48, 183, 1)',
    },
  },
})

