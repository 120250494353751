import {
  CSSProperties,
  FunctionComponent,
  ReactNode,
} from "react";
import { createPortal } from "react-dom";
import styles from "./PortalPopup.module.css";
import React from 'react';
import { Box } from "@mui/material";

type PopupProps = {
  overlayColor?: string;
  isBlur?: boolean;
  onOutsideClick?: () => void;
  children: ReactNode;
  relativeLayer?: HTMLElement | null;
};

const PortalPopup: FunctionComponent<PopupProps> = ({
  children,
  overlayColor,
  isBlur = false,
  onOutsideClick,
  relativeLayer
}) => {
  const setOverlayPosition = () => {
    if (relativeLayer) {
      const rect = relativeLayer.getBoundingClientRect();
      return {
        position: "fixed",
        left: `${rect.left}px`,
        top: `${rect.top}px`,
        width: `${rect.width}px`,
        height: `${rect.height}px`,
      };
    } else {
      return {
        position: "fixed",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      };
    }
  };

  const setModalPosition = (): CSSProperties => {
    if (relativeLayer) {
      const rect = relativeLayer.getBoundingClientRect();
      return {
        position: "absolute",
        left: `${rect.width / 2}px`,
        top: `${rect.height / 2}px`,
        transform: "translate(-50%, -50%)",
      }
    } else {
      return {
        position: "absolute",
        left: '50%',
        top: `50%`,
        transform: "translate(-50%, -50%)",
      }
    }
  }

  const onOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (
      onOutsideClick &&
      (e.target as HTMLElement).classList.contains(styles.portalPopupOverlay)
    ) {
      onOutsideClick();
    }
    e.stopPropagation();
  }


  return (
    <Portal>
      <Box
        className={styles.portalPopupOverlay}
        sx={{
          backgroundColor: overlayColor,
          backdropFilter: isBlur ? "blur(4px)" : "none",
          ...setOverlayPosition()
        }}
        onClick={onOverlayClick}
      >
        <Box style={setModalPosition()}>
          {children}
        </Box>
      </Box>
    </Portal >
  );
};

type PortalProps = {
  children: ReactNode;
  containerId?: string;
};

export const Portal: FunctionComponent<PortalProps> = ({
  children,
  containerId = "portals",
}) => {
  let portalsDiv = document.getElementById(containerId);
  if (!portalsDiv) {
    portalsDiv = document.createElement("div");
    portalsDiv.setAttribute("id", containerId);
    document.body.appendChild(portalsDiv);
  }

  return createPortal(children, portalsDiv);
};
export default PortalPopup;
