import React from "react";
import styles from "./PagesTabs.module.scss";
import { Box, Divider } from "@mui/material";
import { PagesTabsType, TabsType } from "../../types/general/pagesTabs";
import { RegularTab } from "./features/RegularTab";
import { FeedTab } from "./features/FeedTab";
import { SettingsTab } from "./features/SettingTab";

type Props = {
  tabs: PagesTabsType
  isPosibleDelete?: boolean;
  tabsType: TabsType;
}

export const PagesTabs = ({ tabs, tabsType }: Props) => {

  if (tabsType === TabsType.feed) {
    return (
      <Box className={styles.pagesTabs}>
        <Box className={styles.pagesTabs__tabs}>
          {tabs.map(tab => (
            <FeedTab
              key={tab.id}
              title={tab.title}
              link={tab.link}
              tabId={tab.id}
              feedCount={tab.feedCount}
            />
          ))}
        </Box>
        <Divider sx={{ color: '#c7d1e5', marginTop: '2px' }} />
      </Box>
    )
  }

  if (tabsType === TabsType.setting) {
    return (
      <Box className={styles.pagesTabs}>
        <Box className={styles.pagesTabs__tabs}>
          {tabs.map(tab => (
            <SettingsTab
              key={tab.id}
              title={tab.title}
              link={tab.link}
              disabled={tab.disabled}
            />
          ))}
        </Box>
        <Divider sx={{ color: '#c7d1e5', marginTop: '2px' }} />
      </Box>
    )
  }

  return (
    <Box className={styles.pagesTabs}>
      <Box className={styles.pagesTabs__tabs}>
        {tabs.map(tab => (
          <RegularTab
            key={tab.id}
            title={tab.title}
            link={tab.link}
            disabled={tab.disabled}
          />
        ))}
      </Box>
      <Divider sx={{ color: '#c7d1e5', marginTop: '2px' }} />
    </Box>
  )
}
