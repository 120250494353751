import * as React from 'react';
import { BotCard } from './botCard';
import style from '../../botManagementPage.module.scss'
import { Box, CircularProgress, LinearProgress, Typography } from '@mui/material';
import { Bot } from '../../../../types/pages/botManagmentPage/bot';
import Divider from '@mui/material/Divider';
import { BotHeader } from './botHeader';
import { MainActionButton } from '../../../../components/actionButton/MainActionButton';
import { usePopupActions } from '../../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { useBotManagementData } from '../../../../utils/redux/storeHooks/dataHooks/useBotManagementData';
import { useBotManagementActions } from '../../../../utils/redux/storeHooks/actionsHooks/botManagementActions';
import { useEffect } from 'react';

export const BotsList = () => {
  const { setPopUpAddBotOpen } = usePopupActions()
  const { bots, isBotsLoading } = useBotManagementData()
  const { getBots } = useBotManagementActions()

  const openPopUp = () => {
    setPopUpAddBotOpen(true)
  }

  useEffect(() => {
    getBots()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isBotsLoading) {
    return (
      <Box className={style.botManagement__botsContainer} boxShadow={2}>
        <Typography className={style.botManagement__mainTitle}>
          Bot`s list
        </Typography>
        <Divider sx={{ width: '100%' }} />
        <BotHeader />
        <Divider sx={{ width: '100%' }} />
        <Box className={style.botManagement__botsLoadingContainer}>
          <CircularProgress color='inherit' />
        </Box>
        <MainActionButton title='Add bot' actionOnClick={openPopUp} />
      </Box>
    )
  }

  return (
    <Box className={style.botManagement__botsContainer} boxShadow={2}>
      <Typography className={style.botManagement__mainTitle}>
        Bot`s list
      </Typography>
      <Divider sx={{ width: '100%' }} />
      <BotHeader />
      <Divider sx={{ width: '100%' }} />
      {isBotsLoading && <LinearProgress sx={{ width: '100%' }} color='inherit' />}
      <Box className={style.botManagement__bots}>
        {bots.map(bot => <BotCard key={bot.uidd} bot={bot} />)}
      </Box>
      <MainActionButton title='Add bot' actionOnClick={openPopUp} />
    </Box>
  )
}

