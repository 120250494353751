import { IconButton, InputAdornment, TextField, styled } from '@mui/material';
import * as React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    height: '40px',
    fontFamily: 'Kanit',
    borderRadius: '1px',
    padding: '0px',
    '&:hover fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.247)',
    },
    '& .MuiInputBase-input': {
      '&::placeholder': {
        fontWeight: '300',
        marginRight: '12px',
        color: '#6F7D9B',
        fontSize: '16px'
      },
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(109, 48, 183, 1)',
      boxShadow: 'rgba(109, 48, 183, 1) 0px 1px 3px'
    }
  },
})

type Props = {
  inputValue: string;
  setFunction: (inputValue: string, isClear?: boolean) => void;
  inputWidth?: number;
  inputPlaceholder?: string;
  isNeedToChangeRedux?: boolean;
}

export const StyledSearchBar = ({ inputValue, setFunction, inputWidth, inputPlaceholder, isNeedToChangeRedux }: Props) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFunction(event.target.value)
  };

  const searchOnEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setFunction(inputValue);
    }
  };

  const clearInput = () => {
    setFunction('')
  };

  return (
    <StyledTextField
      placeholder={inputPlaceholder || "Search"}
      value={inputValue === 'none' ? '' : inputValue}
      onChange={handleInputChange}
      onKeyUp={searchOnEnter}
      sx={{
        width: inputWidth || '250px',
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setFunction(inputValue)}
            >
              <SearchIcon sx={{ color: '#6F7D9B' }} />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => clearInput()}
            >
              {inputValue ? <CloseIcon /> : null}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

