import { CircularProgress, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { useState } from "react";
import { useBotManagementActions } from "../../../../utils/redux/storeHooks/actionsHooks/botManagementActions";
import { Bot } from "../../../../types/pages/botManagmentPage/bot";
import { useBotManagementData } from "../../../../utils/redux/storeHooks/dataHooks/useBotManagementData";
import { useLocation } from "react-router-dom";
import CookieIcon from '@mui/icons-material/Cookie';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import AirlineSeatIndividualSuiteIcon from '@mui/icons-material/AirlineSeatIndividualSuite';


type Props = {
  bot: Bot;
}

export const DropDownMenu = ({ bot }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {
    checkValidCookiesForBot,
    addToDailyFeedWorkList,
    addToInitialFeedWorkList,
    addToLeadsWorkList,
    stopWork,
  } = useBotManagementActions();
  const {
    checkingCookieForBot,
    botUuidStopWork
  } = useBotManagementData()
  const pathname = useLocation().pathname;

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const checkValidCookies = () => {
    checkValidCookiesForBot({ userUuid: bot.uidd })
    handleClose()
  }

  const onStopWorkHandler = () => {
    stopWork({ botUuid: bot.uidd })
    handleClose()
  }

  const onAddToWorkerList = () => {
    switch (pathname) {
      case '/bot-management':
        addToDailyFeedWorkList(bot)
        break;
      case '/bot-management/initial-feed':
        addToInitialFeedWorkList(bot)
        break;
      case '/bot-management/leads':
        addToLeadsWorkList(bot);
        break;
    }
    handleClose()
  }

  const isLoading = checkingCookieForBot === bot.uidd || botUuidStopWork === bot.uidd;

  return (
    <>
      {isLoading
        ? <CircularProgress sx={{ color: '#3B2470' }} size={41} />
        : (
          <IconButton
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            onClick={(event) => handleOpenMenu(event)}
          >
            <MoreVertIcon sx={{ height: '25px', width: '25px' }} />
          </IconButton>
        )}

      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
          disablePadding: true,
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            boxShadow: '4px 4px 8px 0px rgba(0, 0, 0, 0.15)',
            width: '200px',
            height: '130px',
            overflowX: 'unset',
            overflowY: 'unset'
          },
        }}
      >
        <MenuItem
          sx={{
            padding: '8px',
            height: '40px',
          }}
          onClick={checkValidCookies}
        >
          <ListItemIcon><CookieIcon /></ListItemIcon>
          <ListItemText>Check valid cookies</ListItemText>
        </MenuItem>
        <MenuItem
          sx={{
            padding: '8px',
            height: '40px',
          }}
          onClick={onAddToWorkerList}
          disabled={bot.isBusyLikeBot
            || (pathname === '/bot-management/leads' && !bot.premiumSubscriber)
          }
        >
          <ListItemIcon><PlaylistAddIcon /></ListItemIcon>
          <ListItemText>Add to worker list</ListItemText>
        </MenuItem>
        <MenuItem
          sx={{
            padding: '8px',
            height: '40px',
          }}
          onClick={onStopWorkHandler}
          disabled={!bot.isBusyLikeBot}
        >
          <ListItemIcon><AirlineSeatIndividualSuiteIcon /></ListItemIcon>
          <ListItemText>Stop work</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

