import React from 'react';
import styles from "./DeleteUser.module.scss";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { Box, Typography } from '@mui/material';
import { MainActionButton } from '../../actionButton/MainActionButton';
import { useAdminPageActions } from '../../../utils/redux/storeHooks/actionsHooks/useAdminPageActions';
import { useAdminPageData } from '../../../utils/redux/storeHooks/dataHooks/useAdminPageData';

export const PopUpDeleteUser = () => {
  const { setPopupDeleteUserOpen } = usePopupActions();
  const { deleteUser, setManageCurrentUser } = useAdminPageActions();
  const { manageCurrentUser, isDeletingUser } = useAdminPageData();

  const closePopUp = () => {
    setManageCurrentUser(null)
    setPopupDeleteUserOpen(false)
  }

  const deleteCurretuser = () => {
    deleteUser({ userUuid: manageCurrentUser.uidd })
  }

  return (
    <Box className={styles.popup}>
      <Box className={styles.header}>
        <Box className={styles.header__title}>
          Delete user
        </Box>
        <IconButton
          color="default"
          onClick={closePopUp}
          className={styles.header__closeButton}
        >
          <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
        </IconButton>
      </Box>
      <Box className={styles.content}>
        <Typography className={styles.content__text}>
          Are you sure to delete <span className={styles.content__text_bold}>{manageCurrentUser.firstName} {manageCurrentUser.lastName}</span>?
        </Typography>
      </Box>

      <Box className={styles.footer}>
        <Button
          variant='text'
          onClick={closePopUp}
          className={styles.footer__cancel}
        >
          Cancel
        </Button>
        <MainActionButton
          title='Confirm'
          height={40}
          width={80}
          fontWeight={300}
          actionOnClick={deleteCurretuser}
          isLoading={isDeletingUser}
          isDisabled={isDeletingUser}
        />
      </Box>
    </Box>
  );
};
