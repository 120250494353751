import { Box } from "@mui/material";
import { motion } from "framer-motion";
import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
}

const animations = {
  hidden: {
    x: 100,
    transition: { duration: 0.3 },
    opacity: 0
  },
  animate: {
    x: 0,
    transition: { duration: 0.3 },
    opacity: 1
  },
  // exit: {
  //   x: 100,
  //   transition: { duration: 0.1 },
  // },
}

export const AnimateFilter = ({ children }: Props) => {
  return (
    <Box>
      <motion.div
        variants={animations}
        initial='hidden'
        animate='animate'
        transition={{ ease: "linear" }}
      // exit='exit'
      >
        {children}
      </motion.div>
    </Box>
  )
}

