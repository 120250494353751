import * as React from 'react';
import { TableCell, TableHead, TableRow } from "@mui/material";
import { headCellsTeams } from '../../../../utils/pages/adminPage/headCellsTeams';

export const TableTeamsHeader = () => {
  return (
    <>
      <TableHead sx={{
        border: '0px',
        width: '100%',
      }}
      >
        <TableRow sx={{ zIndex: 0 }}>
          {headCellsTeams.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={
                headCell.id === 'teamName'
                  ? 'left'
                  : 'center'
              }
              sx={{
                width: 'auto',
                height: '64px',
                fontFamily: 'Kanit',
                fontSize: '16px',
                lineHeight: '27px',
                color: '#191F2E',
                padding: '0px 12px',
                zIndex: 100,
              }}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}
