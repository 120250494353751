import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest } from "../../../general/https";
import { checkErrorStatus } from "../../../general/checkErrorStatus";
import { GetSideBarDataResponse } from "./types";

export const getSideBarData = createAsyncThunk<GetSideBarDataResponse>(
  'sidebarSlice/getSideBarData',
   async (_, {rejectWithValue}) => {
    try {
      const response = await getRequest(`/users-information/get-sidebar-data`, true)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }  
)