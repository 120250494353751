import * as React from 'react';
import { PostContent } from './PostContent';
import { Avatar, Box } from '@mui/material';
import style from '../../newFeedPage.module.scss'
import { PostDelete } from './PostDelete';
import { Post } from '../../../../types/entities/post';

type Props = {
  post: Post
}

export const SinglePost = ({ post }: Props) => {
  const {
    firstName,
    profileImg,
    lastName,
    title,
    timeOfPosted,
    postText,
    activity,
    id,
    userIdentity,
    isHidden,
  } = post;

  return (
    <Box className={style.post}>
      <Avatar
        alt={firstName}
        src={profileImg}
        sx={{ width: 56, height: 56 }}
      />
      <PostContent
        firstName={firstName}
        lastName={lastName}
        additionalInfo={title}
        postedDate={timeOfPosted}
        postText={postText}
        activity={activity}
        postId={id}
        userIdentity={userIdentity}
        isHidden={isHidden}
      />
      <PostDelete postId={id} />
    </Box>
  )
}

