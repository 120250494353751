import * as React from 'react';
import style from '../../botManagementPage.module.scss'
import { WorkerList } from '../actions/workerList';
import { Box, Typography } from '@mui/material';
import { useBotManagementData } from '../../../../utils/redux/storeHooks/dataHooks/useBotManagementData';
import { useBotManagementActions } from '../../../../utils/redux/storeHooks/actionsHooks/botManagementActions';
import { MainActionButton } from '../../../../components/actionButton/MainActionButton';
import { SelectUser } from '../actions/selectUser';


export const DailyFeedTab = () => {
  const { dailyFeedWorkerList, isLoadingRunCheckFeedDaily, selectedUserForDailyFeed } = useBotManagementData();
  const { checkFeedDaily, removeFromDailyFeedWorkList } = useBotManagementActions();

  const onCheckFeedDaily = () => {
    checkFeedDaily({
      botsUuids: dailyFeedWorkerList.map(bot => bot.uidd),
      userUuid: selectedUserForDailyFeed?.uidd || ''
    })
  }

  return (
    <Box className={style.botManagement__actions_content}>
      <WorkerList workerList={dailyFeedWorkerList} removeFromWorkList={removeFromDailyFeedWorkList} />
      <Box className={style.botManagement__actions_selectUser}>
        <SelectUser />
      </Box>
      <Box className={style.botManagement__actions_runParsing}>
        <Typography className={style.botManagement__actions_actionTitle}>
          Action:
        </Typography>
        <MainActionButton
          title='Run parsing'
          actionOnClick={onCheckFeedDaily}
          isLoading={isLoadingRunCheckFeedDaily}
          isDisabled={isLoadingRunCheckFeedDaily}
          height={50}
        />
      </Box>
    </Box>
  )
}

