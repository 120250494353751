import React from 'react';
import { Box, Typography } from '@mui/material';

export const NoAddLinks = () => {
  return (
    <Box sx={{
      height: '220px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '48px',
      marginBottom: '48px'
    }}>
      <img alt="" src="/noLinks.png" />
      <Typography sx={{
        color: '#6F7D9B',
        fontFamily: 'Kanit',
        fontWeight: 300,
        fontSize: 18,
        marginTop: '8px'
      }}>
        You have no added link yet
      </Typography>
    </Box>
  )
}

