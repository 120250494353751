import { Box, Typography } from "@mui/material";
import * as React from 'react';
import scss from '../AutomationPage.module.scss'
import { useLocation } from "react-router-dom";

export const PageTitle = () => {
  const pathname = useLocation().pathname

  const isAutomationTab = pathname.startsWith('/settings/automation')
  if (isAutomationTab) {
    return (
      <Box className={scss.pageTitle}>
        <Typography className={scss.pageTitle__textMain}>
          Welcome to the automation settings!
        </Typography>
        <Typography className={scss.pageTitle__textSecondary}>
          You can set daily comments quota, delay between comments for your custom campaigns.
        </Typography>
      </Box>
    )
  }

  return (
    <Box className={scss.pageTitle}>
      <Typography className={scss.pageTitle__textMain}>
        Welcome to the prompt creation settings!
      </Typography>
      <Typography className={scss.pageTitle__textSecondary}>
        You can create up to 5 prompts for your custom campaigns.
      </Typography>
    </Box>
  )
}

