import { HeadCell } from "../../../types/pages/dashboardLeadsPeoplePage/headCell";

export const headCellsTeams: readonly HeadCell[] = [
  {
    id: 'teamName',
    numeric: false,
    disablePadding: false,
    label: 'Team Name',
    disableSorting: false,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 150,
    minWidth: 150,
  },
  {
    id: 'usersCount',
    numeric: false,
    disablePadding: false,
    label: 'Users count',
    disableSorting: false,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 50,
    minWidth: 50,
  },
  
  {
    id: 'update',
    numeric: true,
    disablePadding: false,
    label: '',
    disableSorting: true,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 50,
    minWidth: 50,
  },
  {
    id: 'Delete',
    numeric: true,
    disablePadding: false,
    label: '',
    disableSorting: true,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 50,
    minWidth: 50,
  }
];