import { useAppSelector } from '../typedHooks';

export const useBotManagementData = () => {
  const {
    bots,
    isBotsLoading,
    isGetBotsError,
    dailyFeedWorkerList,
    initialFeedWorkerList,
    leadsWorkerList,
    checkingCookieForBot,
    isLoadingRunCheckFeedDaily,
    users,
    selectedUserForDailyFeed,
    isUsersLoading,
    selectedUserForInitialFeedParse,
    isLoadingRunCheckFeedInitial,
    userUuidForAddBot,
    userUuidDeleteBot,
    botUuidStopWork,
    selectedUserForLoadLeads,
    isLoadingRunLoadLeadsSnGet,
    isStatisticLoading,
    botStatistic,
    botStatisticForChart,
    isSnLinksLoading,
    snLinks,
    selectedSnLinkToInitialParse
  } = useAppSelector((state) => state.botManagementReducer);

  return {
    bots,
    isBotsLoading,
    isGetBotsError,
    dailyFeedWorkerList,
    initialFeedWorkerList,
    leadsWorkerList,
    checkingCookieForBot,
    isLoadingRunCheckFeedDaily,
    users,
    selectedUserForDailyFeed,
    isUsersLoading,
    selectedUserForInitialFeedParse,
    isLoadingRunCheckFeedInitial,
    userUuidForAddBot,
    userUuidDeleteBot,
    botUuidStopWork,
    selectedUserForLoadLeads,
    isLoadingRunLoadLeadsSnGet,
    isStatisticLoading,
    botStatistic,
    botStatisticForChart,
    isSnLinksLoading,
    snLinks,
    selectedSnLinkToInitialParse
  };
};