import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AutomationPageInitialState,} from './types';
import { deletePrompt, getAllPrompts, getAutomationConfig, getLeadsToExclude, runAutomation, saveNewPrompt, updateConfig, updateIsGenerateMessage, updatePrompt } from './thunks';
import { LeadInformation } from '../../../../types/entities/leadInformation';
import { PromptV2Link } from '../../../../types/pages/automationPage/promptV2Names';
import { initialPrompt } from '../../../pages/automationPage/initialPrompt';
import { PromptV2 } from '../../../../types/entities/promptV2';

const initialState: AutomationPageInitialState = {
  currentNumberOfReplyForToday: 0,
  delayBetweenComments: [0, 5],
  scrollStep: 0,
  getAutomationDataloading: false,
  dailyCommentsQouta: 10,
  isUpdating: false,
  leadsList: [],
  excludeLeadsList: [],
  limitOfReplyForADay: 0,
  useStaticPrompt: false,
  //run automation
  automationTab: null,
  isRunAutomationRequest: false,
  isAutomationRunning: false,
  runAutomationStep: 'FIRST',
  runAutomationPromptId: null,
  //get leads
  isGetLeadsToExcludeRequest: false,
  currentPage: 0,
  hasMore: true,
  isShowOnlyExcluded: false,
  inputName: '',
  //prompts
  isGetAllPromptsLoading: false,
  prompts: [],
  initialPrompts: [initialPrompt],
  defaultPrompt: null,
  messagePrompt: null,
  invitePrompt: null,
  dontGenerateDM: false,
  dontGenerateInviteMessage: false,
  isGenerateMessageUpdating: false,
  isNewPromptSaving: false,
  isPromptUpdating: false,
  isPromptDeleting: false,
}

export const automationSlice = createSlice({
  name: 'automationSlice',
  initialState,
  reducers: {
    setCurrentNumberOfReplyForToday: (state, { payload }: PayloadAction<number>) => {
      state.currentNumberOfReplyForToday = payload;
    },
    setLimitOfReplyForADay: (state, { payload }: PayloadAction<number>) => {
      state.limitOfReplyForADay = payload;
    },
    setRunAutomationStep: (state, { payload }: PayloadAction<'FIRST' | 'SECOND'>) => {
      state.runAutomationStep = payload;
    },
    setRunAutomationPromptId: (state, { payload }: PayloadAction<number | null>) => {
      state.runAutomationPromptId = payload;
    },
    setDelayBetweenComments: (state, { payload }: PayloadAction<number[]>) => {
      state.delayBetweenComments = payload;
    },
    setDailyCommentsQouta: (state, { payload }: PayloadAction<number>) => {
      state.dailyCommentsQouta = payload;
    },
    toogleLeadToExcludeLeadList: (state, { payload }: PayloadAction<LeadInformation>) => {
      const currentLead = state.excludeLeadsList.find(lead => lead.leadIdentitySN === payload.leadIdentitySN)
      if(currentLead) {
        state.excludeLeadsList = state.excludeLeadsList.filter(lead => lead.leadIdentitySN !== payload.leadIdentitySN)
        return;
      }
      state.excludeLeadsList.push(payload);
    },
    setShowOnlyExcluded: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowOnlyExcluded = payload;
    },
    setInputName: (state, { payload }: PayloadAction<string>) => {
      state.inputName = payload;
    },
    createPrompt: (state, { payload }: PayloadAction<PromptV2>) => {
      state.prompts.push(payload);
      state.initialPrompts.push(payload)
    },
    clearNewPrompt: (state, { payload }: PayloadAction<PromptV2>) => {
      state.prompts = state.prompts.filter(prompt => prompt.linkName !== PromptV2Link.newPrompt)
      state.initialPrompts = state.initialPrompts.filter(prompt => prompt.linkName !== PromptV2Link.newPrompt)
    },
    clearExistPrompt: (state, { payload }: PayloadAction<PromptV2>) => {
      const currentInitialPrompt = state.initialPrompts.find(prompt => prompt.linkName === payload.linkName);
      const currentPromptIndex = state.prompts.findIndex(prompt => prompt.linkName === payload.linkName)
      if (currentPromptIndex !== -1 && currentInitialPrompt) {
        state.prompts[currentPromptIndex] = currentInitialPrompt;
      }
    },
    setEmojiChecked: (state, { payload }: PayloadAction<{isChecked: boolean, currentPromptLink: string}>) => {
      const currentPrompt = state.prompts.find(prompt => prompt.linkName === payload.currentPromptLink);
      if(currentPrompt) {
        currentPrompt.includeEmoji = payload.isChecked
      }
    },
    setHashTagsChecked: (state, { payload }: PayloadAction<{isChecked: boolean, currentPromptLink: string}>) => {
      const currentPrompt = state.prompts.find(prompt => prompt.linkName === payload.currentPromptLink);
      if(currentPrompt) {
        currentPrompt.includeHashTags = payload.isChecked
      }
    },
    setInputPromptName: (state, { payload }: PayloadAction<{inputValue: string, currentPromptLink: string}>) => {
      const currentPrompt = state.prompts.find(prompt => prompt.linkName === payload.currentPromptLink);
      if(currentPrompt) {
        currentPrompt.name = payload.inputValue
      }
    },
    setTextareaPrompt: (state, { payload }: PayloadAction<{inputValue: string, currentPromptLink: string}>) => {
      const currentPrompt = state.prompts.find(prompt => prompt.linkName === payload.currentPromptLink);
      if(currentPrompt) {
        currentPrompt.prompt = payload.inputValue
      }
    },
    setContextVariables: (state, { payload }: PayloadAction<{contextVariables: string[], currentPromptLink: string}>) => {
      const currentPrompt = state.prompts.find(prompt => prompt.linkName === payload.currentPromptLink);
      if(currentPrompt) {
        currentPrompt.contextVariables = payload.contextVariables
      }
    },
          
  },
  extraReducers: (builder) => {
    //Get automation config
    builder.addCase(getAutomationConfig.pending, (state) => {
      state.getAutomationDataloading = true;
    })
    builder.addCase(getAutomationConfig.fulfilled, (state, action) => {
      state.delayBetweenComments = [action.payload.minDelayBetweenReply, action.payload.maxDelayBetweenReply]
      state.currentNumberOfReplyForToday = action.payload.currentNumberOfReplyForToday;
      state.isAutomationRunning = action.payload.isAutomationRunning;
      state.automationTab = action.payload.tab;
      state.getAutomationDataloading = false;
      state.limitOfReplyForADay = action.payload.limitOfReplyForADay
      state.dailyCommentsQouta = action.payload.limitOfReplyForADay
      state.useStaticPrompt = action.payload.useStaticPrompt
      state.dontGenerateDM = action.payload.dontGenerateDM
      state.dontGenerateInviteMessage = action.payload.dontGenerateInviteMessage
      state.runAutomationPromptId = action.payload.promptId
    })    
    builder.addCase(getAutomationConfig.rejected, (state, action) => {
      state.getAutomationDataloading = false;
    })
    //Update config
    builder.addCase(updateConfig.pending, (state) => {
      state.isUpdating = true;
    })
    builder.addCase(updateConfig.fulfilled, (state, action) => {
      state.delayBetweenComments = [action.payload.minDelayBetweenReply, action.payload.maxDelayBetweenReply]
      state.currentNumberOfReplyForToday = action.payload.currentNumberOfReplyForToday;
      state.isUpdating = false;
      state.dailyCommentsQouta = action.payload.limitOfReplyForADay
      state.limitOfReplyForADay = action.payload.limitOfReplyForADay
    })
    builder.addCase(updateConfig.rejected, (state, action) => {
      state.isUpdating = false;
    })
    //runAutomation
    builder.addCase(runAutomation.pending, (state, action) => {
      state.isRunAutomationRequest = true;
    })
    builder.addCase(runAutomation.fulfilled, (state, action) => {
      state.runAutomationStep = 'FIRST'
      if(action.payload.isAutomationRunning) {
        state.automationTab = action.meta.arg.tab;
        state.isAutomationRunning = true;
        state.isRunAutomationRequest = false;
        return;
      }

      state.automationTab = null;
      state.isAutomationRunning = false;
      state.isRunAutomationRequest = false;
    })
    builder.addCase(runAutomation.rejected, (state, action) => {
      state.isRunAutomationRequest = false;
    })
    //get leads to exclude
    builder.addCase(getLeadsToExclude.pending, (state, action) => {
      state.isGetLeadsToExcludeRequest = true;
    })
    builder.addCase(getLeadsToExclude.fulfilled, (state, action) => {
      const name = action.meta.arg.name;
      if (name) {
        state.leadsList = action.payload.leads;
        state.currentPage = 0;
      } else {
        if(state.currentPage === 0) {
          state.leadsList = [];
        }
        state.leadsList.push(...action.payload.leads);
        state.currentPage = state.currentPage + 1;
      }
      state.excludeLeadsList.push(...action.payload.alreadyExcludedLeads)
      state.hasMore = !(action.payload.leads.length < 10)
      state.isGetLeadsToExcludeRequest = false;
    })
    builder.addCase(getLeadsToExclude.rejected, (state, action) => {
      state.isGetLeadsToExcludeRequest = false;
    })
    //get all prompts
    builder.addCase(getAllPrompts.pending, (state) => {
      state.isGetAllPromptsLoading = true;
    })
    builder.addCase(getAllPrompts.fulfilled, (state, action) => {      
      state.prompts = action.payload;
      state.initialPrompts = action.payload;
      state.isGetAllPromptsLoading = false;
    })
    builder.addCase(getAllPrompts.rejected, (state, action) => {
      state.isGetAllPromptsLoading = false;
    })
    //update generate message for invite
    builder.addCase(updateIsGenerateMessage.pending, (state) => {
      state.isGenerateMessageUpdating = true;
    })
    builder.addCase(updateIsGenerateMessage.fulfilled, (state, action) => {      
      state.dontGenerateDM = action.payload.dontGenerateDM
      state.dontGenerateInviteMessage = action.payload.dontGenerateInviteMessage
      state.isGenerateMessageUpdating = false;
    })
    builder.addCase(updateIsGenerateMessage.rejected, (state) => {
      state.isGenerateMessageUpdating = false;
    })
    //save new prompt
    builder.addCase(saveNewPrompt.pending, (state) => {
      state.isNewPromptSaving = true;
    })
    builder.addCase(saveNewPrompt.fulfilled, (state, action) => {
      state.prompts.push(action.payload)
      state.initialPrompts.push(action.payload)
      const currentPromptIndex = state.prompts.findIndex(prompt => prompt.linkName === PromptV2Link.newPrompt)
      if (currentPromptIndex !== -1) {
        state.prompts[currentPromptIndex] = initialPrompt;
      }
     
      state.isNewPromptSaving = false;
    })
    builder.addCase(saveNewPrompt.rejected, (state) => {
      state.isNewPromptSaving = false;
    })
    //update prompt
    builder.addCase(updatePrompt.pending, (state) => {
      state.isPromptUpdating = true;
    })
    builder.addCase(updatePrompt.fulfilled, (state, action) => {
      state.initialPrompts = state.initialPrompts.filter(prompt => prompt.id !== action.payload.id)
      state.initialPrompts.push(action.payload)
      state.isPromptUpdating = false;
    })
    builder.addCase(updatePrompt.rejected, (state) => {
      state.isPromptUpdating = false;
    })
    //delete prompt
    builder.addCase(deletePrompt.pending, (state) => {
      state.isPromptDeleting = true;
    })
    builder.addCase(deletePrompt.fulfilled, (state, action) => {
      state.initialPrompts = state.initialPrompts.filter(prompt => prompt.id !== action.meta.arg.id)
      state.prompts = state.prompts.filter(prompt => prompt.id !== action.meta.arg.id)
      state.isPromptDeleting = false;
    })

    builder.addCase(deletePrompt.rejected, (state) => {
      state.isPromptDeleting = false;
    })
  }
});

export const automationActions = automationSlice.actions;
export const automationReducer = automationSlice.reducer;