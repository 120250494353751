import { Box, Typography } from '@mui/material';
import * as React from 'react';
import scss from '../../AutomationPage.module.scss'
import { useAutomationData } from '../../../../utils/redux/storeHooks/dataHooks/useAutomationData';
import { useAutomationActions } from '../../../../utils/redux/storeHooks/actionsHooks/useAutomationActions';
import { MainActionButton } from '../../../../components/actionButton/MainActionButton';



export const Actions = () => {
  const { updateConfig } = useAutomationActions();
  const {
    delayBetweenComments,
    dailyCommentsQouta,
    isUpdating,
    isAutomationRunning,
    isRunAutomationRequest,
  } = useAutomationData();

  const handleUpdateConfig = () => {
    const body = {
      maxNumberOfReply: 0,
      minDelayBetweenReply: delayBetweenComments[0],
      maxDelayBetweenReply: delayBetweenComments[1],
      totalCountOfReply: 0,
      isRunning: false,
      dailyCommentsQouta
    }
    updateConfig(body)
  };
  return (
    <Box className={scss.action}>
      <Box className={scss.action__actionButtonWrapper}>
        <MainActionButton
          title='Save'
          actionOnClick={handleUpdateConfig}
          isDisabled={isAutomationRunning || isRunAutomationRequest || isUpdating}
          isLoading={isUpdating}
          height={43}
          fontWeight={300}
          width={450}

        />
      </Box>
    </Box>
  )
}

