import { Plan } from "../../types/components/upgradePlan/plans";
import { SubscriptionPlan } from "../../types/components/upgradePlan/subscriptionPlansName";

export const plans: Plan[] = [
  {
    title: 'Starter',
    enumTitle: SubscriptionPlan.STARTER,
    leadsCount: 2500,
    price: 250,
    features: [
      'Centralized dashboard of all leads',
      'Personalized feed of LinkedIn post',
      'Advanced feed filtration',
      'Leads management',
      'Conversation starters',
      'AI-powered comments and messages',
      'Automation included for better reach',
      'Chrome extension',
      'Sales Nav integration'
    ],
    isBestDeal: false
  },
  {
    title: 'Starter extended',
    enumTitle: SubscriptionPlan.STARTER_EXTENDED,
    leadsCount: 5000,
    price: 500,
    features: [
      'All starter plan features'
    ],
    isBestDeal: true
  },
  {
    title: 'Pro',
    enumTitle: SubscriptionPlan.PRO,
    leadsCount: 10000,
    price: 1000,
    features: [
      'All starter plan features'
    ],
    specialFeatures: [
      'Dedicated customer success manager',
      'Prompts engineering'
    ],
    isBestDeal: false
  }
]