import * as React from 'react';
import style from '../../botManagementPage.module.scss'
import { useEffect, useState } from 'react';
import { Autocomplete, Box, CircularProgress, TextField, Typography } from '@mui/material';
import { useBotManagementActions } from '../../../../utils/redux/storeHooks/actionsHooks/botManagementActions';
import { useBotManagementData } from '../../../../utils/redux/storeHooks/dataHooks/useBotManagementData';
import { useLocation } from 'react-router-dom';
import { Bot } from '../../../../types/pages/botManagmentPage/bot';

export const SelectUser = () => {
  const [open, setOpen] = useState(false);
  const {
    getUsers,
    setSelectedUserForDailyFeedParse,
    setSelectedUserForInitialFeedParse,
    setSelectedUserForLoadLeads
  } = useBotManagementActions();
  const { users, isUsersLoading } = useBotManagementData();
  const pathname = useLocation().pathname;

  const onChangeHandler = (event: React.SyntheticEvent<Element, Event>, newValue: Bot) => {
    switch (pathname) {
      case '/bot-management':
        setSelectedUserForDailyFeedParse(newValue)
        break;
      case '/bot-management/initial-feed':
        setSelectedUserForInitialFeedParse(newValue)
        break;
      case '/bot-management/leads':
        setSelectedUserForLoadLeads(newValue)
        break;
    }

  }

  useEffect(() => {
    if (!users.length) {
      getUsers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box className={style.botManagement__actions_selectUserContainer}>
      <Typography className={style.botManagement__actions_actionTitle}>
        Select user
      </Typography>
      <Autocomplete
        id="asynchronous-demo"
        sx={{ width: '300px' }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        className={style.botManagement__actions_autocomplete}
        isOptionEqualToValue={(option, value) => option.firstName === value.firstName}
        getOptionLabel={(user) => user.firstName + ' ' + user.lastName}
        options={users}
        loading={isUsersLoading}
        onChange={(event, newValue) => onChangeHandler(event, newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select user"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isUsersLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      <Typography sx={{ fontSize: 12, alignSelf: 'flex-start', marginTop: 1, fontWeight: 300, color: pathname === '/bot-management/initial-feed' && 'red' }}>
        {pathname === '/bot-management' && '*Optional'}
        {pathname === '/bot-management/initial-feed' && '*Required'}
      </Typography>
    </Box>
  )
}

