import React, { useState } from 'react';
import styles from "./UpgradePlan.module.scss";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { Box, styled, Typography } from '@mui/material';
import { MainActionButton } from '../../actionButton/MainActionButton';
import { useNewFeedData } from '../../../utils/redux/storeHooks/dataHooks/useNewFeedData';
import { useNewFeedActions } from '../../../utils/redux/storeHooks/actionsHooks/useNewFeedActions';
import { plans } from '../../../utils/components/plan';
import { Image } from '@mui/icons-material';
import PeopleIcon from '@mui/icons-material/People';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { SubscriptionPlan } from '../../../types/components/upgradePlan/subscriptionPlansName';

export const PopUpUpgradePlan = () => {
  const { setPopupUpgradePlanOpen } = usePopupActions();
  const closePopUp = () => {
    setPopupUpgradePlanOpen(false)
  }
  const getButtonStyleAndText = (enumTitle: SubscriptionPlan) => {
    switch (enumTitle) {
      case SubscriptionPlan.STARTER:
        return {
          styles: styles.content__actionsContainer_button_current,
          text: 'Current plan'
        }
      case SubscriptionPlan.STARTER_EXTENDED:
        return {
          styles: styles.content__actionsContainer_button_best,
          text: 'Upgrade'
        }
      case SubscriptionPlan.PRO:
        return {
          styles: styles.content__actionsContainer_button_regular,
          text: 'Upgrade'
        }
    }
  }

  return (
    <Box className={styles.popup}>
      <Box className={styles.header}>
        <Box className={styles.header__title}>
          Simple pricing for your business
        </Box>
        <Box className={styles.header__subTitle}>
          Plans that are carefully crafted to suit your business
        </Box>
        <IconButton
          color="default"
          onClick={closePopUp}
          className={styles.header__closeButton}
        >
          <CloseRoundedIcon sx={{ fill: 'rgba(111, 125, 155, 1)' }} />
        </IconButton>
      </Box>

      <Box className={styles.content}>
        {plans.map(plan => {
          const buttonStyle = getButtonStyleAndText(plan.enumTitle)
          return (
            <Box key={plan.title} className={styles.content__card} boxShadow={2}>
              <Box className={styles.content__cardHeader}>
                <img src='/planet.svg' alt='' />
                <Box className={styles.content__cardHeader_cardNameContainer}>
                  <Typography className={styles.content__cardHeader_cardName}>
                    {plan.title}
                  </Typography>
                  <Typography className={styles.content__cardHeader_cardLeads}>
                    {`${plan.leadsCount.toLocaleString('ru-RU')} leads`}
                  </Typography>
                </Box>
              </Box>

              <Box className={styles.content__cardPriceContainer}>
                <Typography className={styles.content__cardPriceContainer_cardPriceMain}>
                  {`$${plan.price}`}
                </Typography>
                <Typography className={styles.content__cardPriceContainer_cardPriceSyb}>
                  /monthly
                </Typography>
              </Box>

              <Box className={styles.content__actionsContainer}>
                <Button
                  variant="contained"
                  className={`${styles.content__actionsContainer_button} ${buttonStyle.styles}`}
                  disabled={buttonStyle.text === 'Current plan'}
                >
                  {buttonStyle.text}
                </Button>
              </Box>

              <Box className={styles.content__featuresContainer}>
                <Box className={
                  `${styles.content__featuresContainer_feature} ${styles.content__featuresContainer_featureLeads}`
                }>
                  <PeopleIcon sx={{ color: 'rgba(107, 77, 175, 1)', fontSize: '24px' }} />
                  {`${plan.leadsCount} LinkedIn leads to monitor`}
                </Box>
                {plan.features.map(feature => (
                  <Box key={feature} className={styles.content__featuresContainer_feature}>
                    <CheckCircleIcon sx={{ color: 'rgba(107, 77, 175, 1)', fontSize: '24px' }} />
                    {feature}
                  </Box>
                ))}
              </Box>
            </Box>
          )
        })}
      </Box>
    </Box >
  );
};
