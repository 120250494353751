import { Checkbox, FormControlLabel } from '@mui/material';
import * as React from 'react';

type Props = {
  isChecked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checkBoxName: string;
  label: string;
  labelFontWeight?: number;
  labelFontColor?: string;
}

export const StyledFormControlCheckbox = ({ isChecked, onChange, checkBoxName, label, labelFontColor, labelFontWeight }: Props) => {
  return (
    <FormControlLabel
      sx={{
        display: 'flex',
        gap: '12px',
        '& .MuiFormControlLabel-label': {
          fontFamily: 'Kanit',
          fontWeight: labelFontWeight || 300,
          color: labelFontColor || 'inherit'
        },
      }}
      control={
        <Checkbox checked={isChecked} onChange={onChange} name={checkBoxName} sx={{
          height: 22,
          width: 22,
          color: '#3B2470',
          fontFamily: 'Kanit',
          '&.Mui-checked': { color: '#3B2470' }
        }} />
      }
      label={label}
    />
  )
}

