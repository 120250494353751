import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { Sidebar } from '../sidebar/Sidebar';
import Header from '../header/Header';
import styles from "./LayoutApp.module.scss";
import { useSidebarData } from '../../utils/redux/storeHooks/dataHooks/useSidebarData';
import { Box } from '@mui/material';

export const LayoutApp = () => {
  const { isSideBarWide } = useSidebarData();
  return (
    <div className={styles.app}>
      <Box className={styles.app__sidebar} sx={{
        width: !isSideBarWide ? '88px' : '272px',
        transition: 'width 0.3s ease-in-out',
      }}>
        <Sidebar />
      </Box>
      <Box className={styles.app__content} id='heet-app-content' sx={{ width: !isSideBarWide ? 'calc(100vw -  88px)' : 'calc(100vw -  var(--sidebar-width))' }}>
        {/* <div className={styles.app__header}> */}
        <Header />
        {/* </div> */}
        <Box className={styles.app__page} sx={{ width: !isSideBarWide ? 'calc(100vw -  88px)' : 'calc(100vw -  var(--sidebar-width))' }}>
          <Outlet />
        </Box>
      </Box>
    </div>
  )
}

