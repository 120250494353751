import { useMemo } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useAppDispatch } from '../typedHooks';
import { automationActions } from '../../slices/automationPageSlice/automationSlice';
import { deletePrompt, getAllPrompts, getAutomationConfig, getLeadsToExclude, runAutomation, saveNewPrompt, updateConfig, updateIsGenerateMessage, updatePrompt } from '../../slices/automationPageSlice/thunks';

const rootActions = {
  ...automationActions,
  getAutomationConfig,
  updateConfig,
  runAutomation,
  getLeadsToExclude,
  getAllPrompts,
  updateIsGenerateMessage,
  saveNewPrompt,
  updatePrompt,
  deletePrompt
};

export const useAutomationActions = () => {
  const dispatch = useAppDispatch();
  return useMemo(() => bindActionCreators(rootActions, dispatch), [dispatch]);
};