import React from 'react';
import styles from "./DeleteTeam.module.scss";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { Box, Typography } from '@mui/material';
import { MainActionButton } from '../../actionButton/MainActionButton';
import { useAdminPageActions } from '../../../utils/redux/storeHooks/actionsHooks/useAdminPageActions';
import { useAdminPageData } from '../../../utils/redux/storeHooks/dataHooks/useAdminPageData';

export const PopUpDeleteTeam = () => {
  const { setPopupDeleteTeamOpen } = usePopupActions();
  const { deleteTeam, setManageCurrentTeam } = useAdminPageActions();
  const { manageCurrentTeam, isDeletingTeam } = useAdminPageData();

  const closePopUp = () => {
    setManageCurrentTeam(null)
    setPopupDeleteTeamOpen(false)
  }

  const deleteCurretTeam = () => {
    deleteTeam({ teamsUuid: manageCurrentTeam.teamsUuid })
  }

  return (
    <Box className={styles.popup}>
      <Box className={styles.header}>
        <Box className={styles.header__title}>
          Delete team
        </Box>
        <IconButton
          color="default"
          onClick={closePopUp}
          className={styles.header__closeButton}
        >
          <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
        </IconButton>
      </Box>
      <Box className={styles.content}>
        <Typography className={styles.content__text}>
          Are you sure to delete team<span className={styles.content__text_bold}>{manageCurrentTeam.teamName}</span> ?
        </Typography>
      </Box>

      <Box className={styles.footer}>
        <Button
          variant='text'
          onClick={closePopUp}
          className={styles.footer__cancel}
        >
          Cancel
        </Button>
        <MainActionButton
          title='Confirm'
          height={40}
          width={80}
          fontWeight={300}
          actionOnClick={deleteCurretTeam}
          isLoading={isDeletingTeam}
          isDisabled={isDeletingTeam}
        />
      </Box>
    </Box>
  );
};
