import { useMemo } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useAppDispatch } from '../typedHooks';
import { checkActivityByNotification, getNotificationFromDb, updateViewNotificationStatus } from '../../slices/leadsNotificationSlice/thunks';
import { leadsNotificationActions } from '../../slices/leadsNotificationSlice/leadsNotificationSlice';

const rootActions = {
  ...leadsNotificationActions,
  checkActivityByNotification,
  getNotificationFromDb,
  updateViewNotificationStatus
};

export const useLeadsNotificationActions = () => {
  const dispatch = useAppDispatch();
  return useMemo(() => bindActionCreators(rootActions, dispatch), [dispatch]);
};