import * as React from 'react';
import { Alert, Box, CircularProgress, Slide, Snackbar } from "@mui/material";
import { useLeadsNotificationData } from '../../utils/redux/storeHooks/dataHooks/useLeadsNotificationData';
import { useLeadsNotificationActions } from '../../utils/redux/storeHooks/actionsHooks/useLeadsNotificationActions';

export const NotificationLoadingSnackbar = () => {
  const { isSnackBarLoadingOpen, isNotificationUpdating } = useLeadsNotificationData();
  const { setSnackBarOpen } = useLeadsNotificationActions()

  const onCloseLoadingSnackBar = () => {
    if (!isNotificationUpdating) {
      setSnackBarOpen(false)
    }
  }

  const OnCloseAlert = () => {
    setSnackBarOpen(false)
  }

  return (
    <Slide direction="up" in={isSnackBarLoadingOpen}>
      <Snackbar
        open={isSnackBarLoadingOpen}
        onClose={onCloseLoadingSnackBar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={OnCloseAlert}
          sx={{ width: '500px', color: '#191F2E', fontSize: '18px', bgcolor: '#E6D9F8' }}
          icon={false}
          severity="info"        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', fontFamily: 'Kanit' }}>
            <CircularProgress color='inherit' size={18} />
            Processing your updates, please wait
          </Box>
        </Alert>
      </Snackbar>
    </Slide>
  );
}
