import { Box, Typography } from '@mui/material';
import * as React from 'react';
import style from '../../botManagementPage.module.scss'
import { Outlet } from 'react-router-dom';
import { PagesTabs } from '../../../../components/pagesTabs/PagesTabs';
import { pagesTabs } from '../../../../utils/pages/botManagementPage/tabs';
import { TabsType } from '../../../../types/general/pagesTabs';


export const Actions = () => {

  return (
    <Box className={style.botManagement__actions} boxShadow={2}>
      <Typography className={style.botManagement__mainTitle}>
        Parsing
      </Typography>
      <PagesTabs tabs={pagesTabs} tabsType={TabsType.regular} />
      <Outlet />
    </Box>
  )
}

