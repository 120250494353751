import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { useEffect, useRef } from 'react';
import { TablePagination } from '@mui/material';
import { useTableLeadsData } from '../../utils/redux/storeHooks/dataHooks/useTableLeadsData';
import { useTableLeadsActions } from '../../utils/redux/storeHooks/actionsHooks/useTableLeadsActions';
import { TableLeadsHeader } from './features/TableLeadsHeader';
import { TableLeadsBody } from './features/TableLeadsBody';
import { LoaderSkeleton } from './features/LoaderSkeleton';
import { NoLeads } from '../noLeads/NoLeads';
import { NoDataAtSearch } from '../noDataForSearch/NoDataForSearch';
import { AnimateTab } from '../animateTab/AnimateTab';

export const TableLeads = () => {
  const { rows, rowsPerPage, page, loading, responceText, allLeadsCount } = useTableLeadsData()
  const { setPage, setRowsPerPage } = useTableLeadsActions()
  const tableRef = useRef(null);
  const tableContainerRef = useRef(null);

  const scrollUp = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      // setTimeout(() => {
      //   tableContainerRef.current.style.height = '100%';
      // }, 500)
    }
    // window.scrollTo({
    //   top: 0,
    //   behavior: 'smooth'
    // });
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleScroll = () => {
  //   const tableContainer = tableContainerRef.current;
  //   if (tableRef.current) {
  //     const tableRect = tableRef.current.getBoundingClientRect();
  //     if (tableRect.top <= 20) {
  //       tableContainer.style.height = `calc(100vh - 56px)`
  //       // tableContainer.style.height = `700px`

  //       return;
  //     }
  //     tableContainer.style.height = `100%`
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  useEffect(() => {
    scrollUp()
  }, [page, rowsPerPage])

  // if (responceText === 'add leads') {
  // return <NoLeads />
  // }

  if (rows?.length === 0 && !loading) {
    return <NoDataAtSearch />
  }

  return (
    <AnimateTab>
      <Box sx={{ width: '100%', }} >
        <Paper sx={{ width: '100%', boxShadow: 'unset' }} >
          <TableContainer sx={{ borderRadius: 2, height: 'calc(100vh - 280px)' }} ref={tableContainerRef}>
            <Table
              ref={tableRef}
              aria-labelledby="tableTitle"
              size={'medium'}
              stickyHeader

            >
              <TableLeadsHeader scrollUp={scrollUp} />
              {rows?.length === 0 && loading && <LoaderSkeleton />}
              <TableLeadsBody />
            </Table>

          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={allLeadsCount || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => handleChangePage(newPage)}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton
            showLastButton
          />
        </Paper>
      </Box >
    </AnimateTab>
  );
}
