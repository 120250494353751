import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box } from '@mui/material';
import * as React from 'react';
import style from '../../botManagementPage.module.scss'
import { BotsStatistic } from '../../../../types/pages/botManagmentPage/botStatistic';
import moment from 'moment';
import DoneIcon from '@mui/icons-material/Done';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { BotsCronJobName } from '../../../../types/pages/botManagmentPage/botsCronJobNames';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type Props = {
  bot: BotsStatistic;
}
export const BotStatisticCard = ({ bot }: Props) => {
  const momentDate = moment(bot.lastWorkDate);
  const formattedDateTime = momentDate.format('DD.MM.YY, hh:mm a');
  const totalProcessedLeadsForToday = bot.countOfLeadsProcessedByParsingFeedForToday + bot.countOfLeadsProcessedByParsingLeadsForToday;
  const toalProcessedLeadsForMonth = bot.countOfLeadsProcessedByParsingFeedForMonth + bot.countOfLeadsProcessedByParsingLeadsForMonth;
  return (
    <Box key={bot.id} className={style.botManagement__statistic_card}>
      <Box className={style.botManagement__statistic_card_img}>
        <Avatar
          alt=""
          src={bot.profileImg}
          className={style.botManagement__bots_img}
          sx={{ width: '48px', height: '48px' }}
        />
      </Box>

      <Box className={style.botManagement__statistic_card_name}>
        {bot.firstName + ' ' + bot.lastName}
      </Box>
      <Box className={style.botManagement__statistic_card_workName}>
        {bot.workName === BotsCronJobName.dailyParcingFeed && 'Daily feed'}
        {bot.workName === BotsCronJobName.initialParcingFeed && 'Initial feed'}
        {bot.workName === BotsCronJobName.parcingLead && 'Parcing leads'}
      </Box>
      <Box className={style.botManagement__statistic_card_counter}>
        {totalProcessedLeadsForToday}
      </Box>
      <Box className={style.botManagement__statistic_card_counter}>
        {toalProcessedLeadsForMonth}
      </Box>
      <Box className={style.botManagement__statistic_card_date}>
        {formattedDateTime}
      </Box>
      <Box className={style.botManagement__statistic_card_boolean}>
        {
          bot.islastWorkDone
            ? <DoneIcon sx={{ color: '#BFE8B1' }} />
            : <DoNotDisturbAltIcon sx={{ color: '#6f7d9b' }} />
        }
      </Box>
      <Box className={style.botManagement__statistic_card_boolean}>
        <ErrorOutlineIcon sx={{ color: bot.islastWorkError ? '#db491f' : '#6f7d9b' }} />
      </Box>
      <Box className={style.botManagement__statistic_card_error}>
        <Accordion sx={{ width: '100%', boxShadow: 'none' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ textAlign: 'center' }}
          >
            {bot.lastWorkError ? `${bot.lastWorkError.slice(0, 15)}...` : 'No errors'}
          </AccordionSummary>
          <AccordionDetails sx={{ border: '1px solid #6f7d9b', borderRadius: 2, color: '#db491f', fontWeight: 300 }}>
            {bot.lastWorkError ? bot.lastWorkError : 'This User has no errors.'}
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box >
  )
}

