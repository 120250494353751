import { Box, Typography } from '@mui/material';
import React from 'react';
import styles from "../RunAutomation.module.scss";
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useAutomationData } from '../../../../utils/redux/storeHooks/dataHooks/useAutomationData';

type Props = {
  closePopUp: () => void;
}

export const HeaderPopUp = ({ closePopUp }: Props) => {
  const { runAutomationStep } = useAutomationData()

  const subTitle = runAutomationStep === 'FIRST'
    ? 'Choose a prompt for the automation campaign'
    : 'Leads excluded from automation process.'

  return (
    <Box className={styles.header}>
      <Box className={styles.header__top}>
        <Box className={styles.header__title}>
          Run automation
        </Box>
        <IconButton
          color="default"
          onClick={closePopUp}
          className={styles.header__closeButton}
        >
          <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
        </IconButton>
      </Box>
      <Typography className={styles.header__subTitle}>
        {subTitle}
      </Typography>
    </Box>
  )
}

