import React, { useState } from 'react';
import styles from "./SendCredential.module.scss";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { Autocomplete, Box, styled, TextField, Typography } from '@mui/material';
import { MainActionButton } from '../../actionButton/MainActionButton';
import { StyledTextField } from '../../styledTextField/StyledTextField';
import { useAdminPageData } from '../../../utils/redux/storeHooks/dataHooks/useAdminPageData';
import UserInformation from '../../../types/entities/user_information';
import { useAdminPageActions } from '../../../utils/redux/storeHooks/actionsHooks/useAdminPageActions';

const StyledAutocomplete = styled(Autocomplete)<{ options: UserInformation[] }>({
  '& .MuiOutlinedInput-root': {
    padding: '1px 12px',
    borderRadius: '1px',
    fontFamily: 'Kanit',
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(109, 48, 183, 1)',
    },
  },
})

export const PopUpSendCredential = () => {
  const [selectValue, setSelectValue] = useState<UserInformation | null>(null)
  const { setPopupSendCredentialOpen } = usePopupActions();
  const { users, generatedPassword, isPasswordGenerating, isSendingCredentials } = useAdminPageData();
  const { sendEmailCredentials, generatePassword, resetGeneratedPassword } = useAdminPageActions();

  const handleAutocompleteChange = (_: React.SyntheticEvent<Element, Event>, newValue: UserInformation | null) => {
    const selectedOption = users.find(user => user.uidd === newValue.uidd) || null;
    setSelectValue(selectedOption)
  }

  const closePopUp = () => {
    resetGeneratedPassword()
    setPopupSendCredentialOpen(false)
  }

  const generatePasswordForUser = () => {
    if (selectValue) {
      generatePassword({ userUuid: selectValue.uidd })
    }

  }

  const sendCredentials = () => {
    if (selectValue && generatedPassword) {
      sendEmailCredentials({ password: generatedPassword, userUuid: selectValue.uidd })
    }
  }

  return (
    <Box className={styles.popup}>
      <Box className={styles.header}>
        <Box className={styles.header__title}>
          Send credetials
        </Box>
        <IconButton
          color="default"
          onClick={closePopUp}
          className={styles.header__closeButton}
        >
          <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
        </IconButton>
      </Box>
      <Box className={styles.content}>
        <Typography className={styles.content__text}>
          Please select user and generate password:
        </Typography>
        <Typography className={styles.content__textSub}>
          *Make sure the selected user already has an email address
        </Typography>
        <Box className={styles.content__settings}>
          <Box className={styles.content__setting}>
            <Typography className={styles.content__settingTitle}>
              User:
            </Typography>
            <StyledAutocomplete
              clearIcon={null}
              disablePortal
              id="combo-box-demo"
              options={users.filter(user => !user.password && user.email)}
              getOptionLabel={(option: UserInformation) => `${option.firstName} ${option.lastName}`}
              renderOption={(props, option: UserInformation) => (
                <li {...props}>
                  {option.firstName} {option.lastName}
                </li>
              )} sx={{ width: '300px' }}
              value={selectValue}
              onChange={(event, newValue: UserInformation | null) => handleAutocompleteChange(event, newValue)}
              ListboxProps={{ sx: { "& li": { fontFamily: "Kanit", fontWeight: 300 } } }}
              renderInput={(params) => <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                }}
              />}
            />
          </Box>

          <Box className={styles.content__setting}>
            <Typography className={styles.content__settingTitle}>
              Password:
            </Typography>
            <Box className={styles.content__pass}>
              <StyledTextField sx={{ width: '212px' }} disabled value={generatedPassword || ''} />
              <MainActionButton
                title='Generate'
                width={80}
                height={44}
                isDisabled={isPasswordGenerating || !!selectValue?.password || !selectValue?.email}
                isLoading={isPasswordGenerating}
                actionOnClick={generatePasswordForUser}
              />
            </Box>
          </Box>

        </Box>
      </Box>

      <Box className={styles.footer}>
        <Button
          variant='text'
          onClick={closePopUp}
          className={styles.footer__cancel}
        >
          Cancel
        </Button>
        <MainActionButton
          title='Send'
          height={40}
          width={80}
          fontWeight={300}
          isDisabled={!selectValue || !selectValue?.email || !generatedPassword || isSendingCredentials}
          actionOnClick={sendCredentials}
          isLoading={isSendingCredentials}
        />
      </Box>
    </Box>
  );
};
