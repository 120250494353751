import * as React from 'react';
import scss from './NoDataForSearch.module.scss';
import { Box } from '@mui/material';

export const NoDataAtSearch = () => {
  return (
    <Box className={scss.noLeads}>
      <div className={scss.noLeadsContainer}>
        <label className={scss.title}>
          Nothing found :(
        </label>
        <img src={'/no_lead_found.svg'} alt='no'></img>
      </div>
    </Box>
  )
};