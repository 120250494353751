import { Box, Typography } from "@mui/material"
import * as React from 'react';
import scss from '../../../AutomationPage.module.scss'
import { contextVariables } from "../../../../../types/pages/automationPage/contextVariablesOptions";
import { PromptV2 } from "../../../../../types/entities/promptV2";
import { useAutomationActions } from "../../../../../utils/redux/storeHooks/actionsHooks/useAutomationActions";

type Props = {
  currentPrompt: PromptV2;
}

export const PromptContextVariables = ({
  currentPrompt,
}: Props) => {
  const { setContextVariables } = useAutomationActions()

  const onContextVariablesChange = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = event.target as HTMLDivElement;
    const value = target.innerText
    const currentContextVariables = currentPrompt.contextVariables;
    let changedContextVariables = [...currentContextVariables];

    if (currentPrompt.contextVariables.includes(value)) {
      changedContextVariables = currentContextVariables.filter(variables => variables !== value)
    } else {
      changedContextVariables.push(value)
    }

    setContextVariables({
      contextVariables: changedContextVariables,
      currentPromptLink: currentPrompt.linkName

    })
  }

  return (
    <Box className={scss.promptSetting__variables}>
      <Typography className={scss.promptSetting__sectionTitle}>
        Use context
      </Typography>
      <Box className={scss.promptSetting__varablesWrapper}>
        {contextVariables.map(variable => (
          <Box
            key={variable}
            className={`${scss.promptSetting__variableItem} ${currentPrompt.contextVariables.includes(variable) && scss.promptSetting__variableItem_checked}`}
            onClick={(e) => onContextVariablesChange(e)}
          >
            {variable}
          </Box>
        ))}
      </Box>
    </Box>
  )
}

