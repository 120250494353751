import { styled, Autocomplete } from "@mui/material";

export const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiOutlinedInput-root': {
    padding: '1px 12px',
    borderRadius: '1px',
    fontFamily: 'Kanit',
    '&:hover fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.247)',
    },
    '& .MuiInputBase-input': {
      '&::placeholder': {
        fontWeight: '300',
        marginRight: '4px',
        color: '#6F7D9B',
        fontSize: '16px'
      },
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(109, 48, 183, 1)',
    },
  },
})