import { Box, LinearProgress, TableCell, TableRow } from '@mui/material';
import scss from './TableLoader.module.scss';
import * as React from 'react';

export const TableLoader = () => {
  return (
    <TableRow sx={{ width: 0, height: 0, padding: 0 }}>
      <TableCell sx={{ height: 0, padding: 0 }}>
        <LinearProgress className={scss.tableLoader__progress_top} color='inherit' />
        <Box className={scss.tableLoader} />
      </TableCell>
    </TableRow>
  )
}