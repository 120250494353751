import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import CardMembershipRoundedIcon from '@mui/icons-material/CardMembershipRounded';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { checkUserRole, cleanUpAllStorage, deleteAuthCookies, getUserInformationFromlocalStorage } from '../../utils/general/get_set_user_information';
import { Get } from '../../utils/general/https';
import { useNavigate } from 'react-router-dom';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const userInformation = getUserInformationFromlocalStorage()
  const navigate = useNavigate()
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    Get('/auth/signout').then(res => {
      cleanUpAllStorage();
      deleteAuthCookies()
      localStorage.removeItem('token');
      window.location.href = '/signIn'
      setAnchorEl(null);
    })
  };

  const promptsManager = () => {
    navigate('/prompts-manager')

  };

  const botManagement = () => {
    navigate('/bot-management')
  };

  const adminPage = () => {
    navigate('/admin-page/users')
  };

  const privacyPolicy = () => {
    window.location.href = 'https://heet.ai/privacy-policy'
  }

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2, marginLeft: 0 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar
              sx={{ width: 32, height: 32, mr: '4px' }}
              src={userInformation.profileImg}
              alt={userInformation.firstName}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={privacyPolicy}>
          <ListItemIcon>
            <CardMembershipRoundedIcon style={{ fontSize: 'medium', color: 'black' }} />
          </ListItemIcon>
          Privacy policy
        </MenuItem>
        {checkUserRole('User') && (
          <MenuItem onClick={promptsManager}>
            <ListItemIcon>
              <SettingsOutlinedIcon style={{ fontSize: 'medium', color: 'black' }} />
            </ListItemIcon>
            Prompt Settings
          </MenuItem>
        )}
        {checkUserRole('Master') && (
          <MenuItem onClick={botManagement}>
            <ListItemIcon>
              <AdminPanelSettingsIcon style={{ fontSize: 'medium', color: 'black' }} />
            </ListItemIcon>
            Bots management
          </MenuItem>
        )}
        {checkUserRole('Master') && (
          <MenuItem onClick={adminPage}>
            <ListItemIcon>
              <AdminPanelSettingsIcon style={{ fontSize: 'medium', color: 'black' }} />
            </ListItemIcon>
            Admin page
          </MenuItem>
        )}
        <MenuItem onClick={logOut}>
          <ListItemIcon>
            <Logout style={{ fontSize: 'medium', color: 'black' }} />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment >
  );
}
