import React, { useState } from 'react';
import styles from "./UpdateTeam.module.scss";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { Box, Typography } from '@mui/material';
import { MainActionButton } from '../../actionButton/MainActionButton';
import { useAdminPageData } from '../../../utils/redux/storeHooks/dataHooks/useAdminPageData';
import { useAdminPageActions } from '../../../utils/redux/storeHooks/actionsHooks/useAdminPageActions';
import { StyledTextField } from '../../styledTextField/StyledTextField';

export const PopUpUpdateTeam = () => {
  const { updateTeam, setManageCurrentTeam } = useAdminPageActions();
  const { setPopupUpdateTeamOpen } = usePopupActions();
  const { isUpdatingTeam, manageCurrentTeam } = useAdminPageData();
  const [inputValue, setInputValue] = useState(manageCurrentTeam.teamName);

  const closePopUp = () => {
    setManageCurrentTeam(null)
    setPopupUpdateTeamOpen(false)
  }

  const createNewTeam = () => {
    if (inputValue && inputValue !== manageCurrentTeam.teamName) {
      updateTeam({ teamsUuid: manageCurrentTeam.teamsUuid, newTeamName: inputValue })
    }
  }

  const onInputChange = (value: string) => {
    setInputValue(value)
  }

  return (
    <Box className={styles.popup}>
      <Box className={styles.header}>
        <Box className={styles.header__title}>
          Update team
        </Box>
        <IconButton
          color="default"
          onClick={closePopUp}
          className={styles.header__closeButton}
        >
          <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
        </IconButton>
      </Box>
      <Box className={styles.content}>
        <Typography className={styles.content__text}>
          Enter a new company name
        </Typography>
        <StyledTextField
          value={inputValue}
          fullWidth
          onChange={(e) => onInputChange(e.target.value)}
          required
        />
      </Box>

      <Box className={styles.footer}>
        <Button
          variant='text'
          onClick={closePopUp}
          className={styles.footer__cancel}
        >
          Cancel
        </Button>
        <MainActionButton
          title='Update'
          height={40}
          width={66}
          fontWeight={300}
          isDisabled={isUpdatingTeam || !inputValue.length || inputValue === manageCurrentTeam.teamName}
          actionOnClick={createNewTeam}
          isLoading={isUpdatingTeam}
        />
      </Box>
    </Box>
  );
};
