import { Accordion, AccordionDetails, AccordionSummary, Box, Button, styled } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { PostComment } from './PostComment';
import style from '../../newFeedPage.module.scss'
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const StyledAccordion = styled(Accordion)({
  '& .MuiAccordionSummary-root': {
    padding: '0',
    cursor: 'default'
  },
  '& .MuiAccordionDetails-root': {
    padding: '0',
  }
})

type Props = {
  activity: string;
  postId: number;
  userIdentity: string;
  isHidden: boolean;
}

export const PostActions = ({ activity, postId, userIdentity, isHidden }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isClicked, setIsClicked] = useState(false);


  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    setIsClicked(!isClicked)
  };

  return (
    <Box className={style.post__actions}>
      <StyledAccordion
        expanded={isOpen}
        className={style.post__actions_accordion}
        style={{ cursor: 'default' }}
      >
        <AccordionSummary
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Box className={style.post__actions_btns}>
            <a
              href={`https://www.linkedin.com/feed/update/${activity}`}
              target="_blank"
              rel="noreferrer"
              className={style.post__actions_link}
            >
              <Button
                className={style.post__actions_view}
              >
                View on
                <LinkedInIcon sx={{
                  width: '20px',
                  height: '20px',
                  fill: '#007EBB',
                  backgroundColor:
                    '#D9ECF5',
                }} />
              </Button>
            </a>
            <Button
              className={isClicked ? `${style.post__actions_comment} ${style.post__actions_comment_clicked}` : `${style.post__actions_comment}`}
              onClick={toggleAccordion}
            >

              Comment
            </Button>
          </Box>

        </AccordionSummary>
        <AccordionDetails>
          <PostComment
            postId={postId}
            userIdentity={userIdentity}
            isHidden={isHidden}
          />
        </AccordionDetails>
      </StyledAccordion>
    </Box>
  )
}