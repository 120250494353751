import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RefreshIcon from '@mui/icons-material/Refresh';
import React, { useState } from "react";
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { useParams } from "react-router-dom";
import { usePopupActions } from "../../../utils/redux/storeHooks/actionsHooks/usePopupActions";
import { useNewFeedActions } from "../../../utils/redux/storeHooks/actionsHooks/useNewFeedActions";
import CreateIcon from '@mui/icons-material/Create';

export const DropDownTabMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { setDeleteOrUpdateTabId } = useNewFeedActions();
  const { setPopupRenameTabOpen, setPopUpDeleteTabOpen } = usePopupActions();
  const { tabId } = useParams();

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDeleteOrUpdateTabId(null)
  };

  const renameMode = () => {
    setPopupRenameTabOpen(true)
    setDeleteOrUpdateTabId(tabId)
    setAnchorEl(null);
  }

  const deletMode = () => {
    setPopUpDeleteTabOpen(true)
    setDeleteOrUpdateTabId(tabId)
    setAnchorEl(null);
  }

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-haspopup="true"
        onClick={(event) => handleOpenMenu(event)}
        sx={{ position: 'absolute', right: '-15px', padding: '5px' }}
      >
        <MoreVertIcon sx={{ height: '25px', width: '25px' }} />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
          disablePadding: true,
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            boxShadow: '4px 4px 8px 0px rgba(0, 0, 0, 0.15)',
            width: '150px',
            height: '80px',
            overflowX: 'unset',
            overflowY: 'unset'
          },
        }}
      >
        <MenuItem
          sx={{
            padding: '8px',
            height: '40px',
          }}
          onClick={renameMode}
        >
          <ListItemIcon>
            <CreateIcon sx={{
              height: '40px',
              color: '#191F2E'
            }} />
          </ListItemIcon>
          <ListItemText>Rename</ListItemText>
        </MenuItem>
        <MenuItem
          sx={{
            padding: '8px',
            height: '40px',
          }}
          onClick={deletMode}
        >
          <ListItemIcon>
            <DeleteOutlineRoundedIcon sx={{
              height: '40px',
              color: '#191F2E'
            }} />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

