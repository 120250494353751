import React from 'react';
import scss from '../header.module.scss';
import { Avatar, Box, CircularProgress, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useLeadsNotificationData } from '../../../utils/redux/storeHooks/dataHooks/useLeadsNotificationData';
import { useLeadsNotificationActions } from '../../../utils/redux/storeHooks/actionsHooks/useLeadsNotificationActions';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import { convertPureSecondsToTime } from '../../../utils/general/convert_seconds_to_time';
import ReplyIcon from '@mui/icons-material/Reply';

export const HeaderNotifications = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { notifications, isDeletingNotifications } = useLeadsNotificationData();
  const { updateViewNotificationStatus } = useLeadsNotificationActions();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (notifications?.length) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    updateViewNotificationStatus();
  };

  if (isDeletingNotifications) {
    return (
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
      >
        <CircularProgress sx={{ color: '#191F2E' }} size={24} />
      </IconButton>
    )
  }

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleOpen}
      >
        {!notifications?.length
          ? <NotificationsNoneRoundedIcon style={{ color: '#191F2E' }} />
          : <img alt="" src={'./notification_with_alert.svg'} />
        }
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        sx={{

          padding: '0px !important',
          paddingTop: '0px !important'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {notifications?.map(notification => {
          return (
            <MenuItem
              key={notification.id}
              onClick={handleClose}
              className={scss.notifications__item} >
              <Box className={scss.notifications__userInfo}>
                <a
                  className={scss.notifications__link}
                  href={notification.leadInformations[0]?.profileUrl.toString()}
                  target='_blank'
                  rel="noreferrer"
                >
                  <Avatar
                    src={notification.leadInformations[0]?.profileImg.toString()}
                    className={scss.notifications__img}
                  />
                </a>
                <Box className={scss.notifications__infoContainer}>
                  <Box className={scss.notifications__name}>
                    {notification.leadInformations[0]?.firstName} {notification.leadInformations[0]?.lastName}
                  </Box>
                  {notification.typeOfActivity === 'Like' && (
                    <Box className={scss.notifications__reactionText}>
                      liked your comment
                    </Box>
                  )}


                  {notification.typeOfActivity === 'Reply' && (
                    <Box className={scss.notifications__reactionText}>
                      replied to your comment
                    </Box>
                  )}
                </Box>
              </Box>
              <Box className={scss.notifications__timeWithIcon}>
                <Typography className={scss.notifications__time}>
                  {convertPureSecondsToTime(notification.convertedTime?.toString())}
                </Typography>
                {notification.typeOfActivity === 'Reply' && (
                  <ReplyIcon
                    sx={{
                      color: '#6D30B7'
                    }}
                  />
                )}
                {notification.typeOfActivity === 'Like' && (
                  <ThumbUpRoundedIcon
                    sx={{
                      color: '#25660E'
                    }}
                  />
                )}
              </Box>
            </MenuItem>
          )
        })}
      </Menu >
    </>
  )
}

