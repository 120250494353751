import { HeadCell } from "../../../types/pages/teamManagmentPage/headCell";
import { checkUserRole } from "../../general/get_set_user_information";
export const headCells: readonly HeadCell[] = [
  {
      id: 'firstName',
      numeric: false,
      disablePadding: true,
      label: 'Name',
      disableSorting: false,
      showToolTip: false,
      showColumn: true
  },
  {
      id: 'memberEngagement',
      numeric: false,
      disablePadding: true,
      label: 'Invitations',
      disableSorting: false,
      showToolTip: false,
      showColumn: true
  },
  {
      id: 'messagesSent',
      numeric: false,
      disablePadding: false,
      label: 'Messages',
      disableSorting: false,
      showToolTip: false,
      showColumn: true
  },
  {
      id: 'profileViews',
      numeric: true,
      disablePadding: false,
      label: 'Views',
      disableSorting: false,
      showToolTip: false,
      showColumn: true
  },
  {
      id: 'likes',
      numeric: true,
      disablePadding: false,
      label: 'Likes',
      disableSorting: false,
      showToolTip: false,
      showColumn: true
  },
  {
      id: 'replies',
      numeric: true,
      disablePadding: false,
      label: 'Replies',
      disableSorting: false,
      showToolTip: false,
      showColumn: true
  },

  {
      id: 'pendingReplies',
      numeric: true,
      disablePadding: false,
      label: 'Pending',
      disableSorting: false,
      showToolTip: false,
      showColumn: true
  },
  // {
  //     id: 'successRate',
  //     numeric: true,
  //     disablePadding: false,
  //     label: 'Success rate',
  //     disableSorting: false,
  //     showToolTip: false,
  // },
  {
      id: 'totalLeadCount',
      numeric: true,
      disablePadding: false,
      label: 'Leads',
      disableSorting: false,
      showToolTip: false,
      showColumn: true
  },
  // {
  //     id: 'position',
  //     numeric: true,
  //     disablePadding: false,
  //     label: 'Position',
  //     disableSorting: false,
  //     showToolTip: false,
  // },
  {
      id: 'totalFeedCount',
      numeric: true,
      disablePadding: false,
      label: 'Feeds',
      disableSorting: false,
      showToolTip: false,
      showColumn: checkUserRole('Master') ? true : false
  },
  {
      id: 'role',
      numeric: true,
      disablePadding: false,
      label: 'Role',
      disableSorting: true,
      showToolTip: false,
      showColumn: true
  },
  {
      id: 'company',
      numeric: true,
      disablePadding: false,
      label: 'Company',
      disableSorting: true,
      showToolTip: false,
      showColumn: true
  },
  {
      id: 'id',
      numeric: true,
      disablePadding: true,
      label: '',
      disableSorting: true,
      showToolTip: false,
      showColumn: true
  },
];
