import { useAppSelector } from '../typedHooks';

export const useNotificationData = () => {
  const {
   isSnackbarOpen,
   vertical,
   horizontal,
   message,
   severenity,
   alertTitle,
   duration
  } = useAppSelector((state) => state.notificationReducer);

  return {
    isSnackbarOpen,
    vertical,
    horizontal,
    message,
    severenity,
    alertTitle,
    duration
  };
};