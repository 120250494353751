import { Skeleton, TableBody, TableCell, TableRow } from '@mui/material';
import * as React from 'react';
import { useTableLeadsData } from '../../../utils/redux/storeHooks/dataHooks/useTableLeadsData';
import { TableLoader } from '../../tableLoader/TableLoader';

const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const LoaderSkeleton = () => {
  const { loading } = useTableLeadsData()
  return (
    <TableBody sx={{ position: 'relative' }}>
      {loading && <TableLoader />}
      {arr.map((elem) => (
        <TableRow key={elem} sx={{ maxHeight: '64px' }}>
          <TableCell sx={{ minWidth: 30, maxWidth: 30, padding: '0px 12px' }}>
            <Skeleton variant="rectangular" width='0' height={40} />
          </TableCell>
          <TableCell sx={{ minWidth: 200, maxWidth: 200, padding: '12px 12px' }}>
            <Skeleton variant="rectangular" width='0' height={40} />
          </TableCell>
          <TableCell sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }}>
            <Skeleton variant="rectangular" width='0' height={40} />
          </TableCell>
          <TableCell sx={{ minWidth: 150, maxWidth: 150, padding: '0px 12px' }}>
            <Skeleton variant="rectangular" width='0' height={40} />
          </TableCell>
          <TableCell sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }}>
            <Skeleton variant="rectangular" width='0' height={40} />
          </TableCell>
          <TableCell sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }}>
            <Skeleton variant="rectangular" width='0' height={40} />
          </TableCell>
          <TableCell sx={{ minWidth: 200, maxWidth: 200, padding: '0px 12px' }}>
            <Skeleton variant="rectangular" width='0' height={40} />
          </TableCell>
          <TableCell sx={{ minWidth: 50, maxWidth: 50, padding: '12px 12px' }}>
            <Skeleton variant="rectangular" width='0' height={40} />
          </TableCell>
          <TableCell sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }}>
            <Skeleton variant="rectangular" width='0' height={40} />
          </TableCell>
          {/* <TableCell sx={{ padding: '0px 40px' }}>
            <Skeleton variant="rectangular" width='0' height={40} />
          </TableCell> */}
        </TableRow>
      ))}
    </TableBody>
  )
}