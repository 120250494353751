import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminPageSliceInitialState } from './types';
import UserInformation from '../../../../types/entities/user_information';
import { createTeam, deleteAdditionalWrongInfo, deleteTeam, deleteUser, generatePassword, getTeamsList, getUsersList, sendEmailCredentials, sendEmailExtension, updateTeam, updateUser } from './thunks';
import Teams from '../../../../types/entities/teams';
import { act } from 'react-dom/test-utils';

const initialState: AdminPageSliceInitialState = {
  users: [],
  filtredUsers: [],
  usersCount: 0,
  isUsersLoading: false,
  teams: [],
  teamsCount: 0,
  isTeamsLoading: false,
  manageCurrentUser: null,
  generatedPassword: null,
  isPasswordGenerating: false,
  isSendingExtension: false,
  isSendingCredentials: false,
  isUserUpdating: false,
  isDeletingUser: false,
  isCreatingTeam: false,
  isUpdatingTeam: false,
  isDeletingTeam: false,
  manageCurrentTeam: null,
  isUserRepairing: null,
};

export const adminPageSlice = createSlice({
  name: 'adminPageSlice',
  initialState,
  reducers: {
    setUsers: (state, { payload }: PayloadAction<UserInformation[]>) => {
      state.users = payload;
    },
    setManageCurrentUser: (state, { payload }: PayloadAction<UserInformation | null>) => {
      state.manageCurrentUser = payload;
    },
    setManageCurrentTeam: (state, { payload }: PayloadAction<Teams | null>) => {
      state.manageCurrentTeam = payload;
    },
    setfilteredUsers: (state, { payload }: PayloadAction<UserInformation[]>) => {
      state.filtredUsers = payload;
    },
    resetGeneratedPassword: (state) => {
      state.generatedPassword = null;
    },     
  },
  
  extraReducers: (builder) => {
    //Get Users List
    builder.addCase(getUsersList.pending, (state) => {
      state.isUsersLoading = true;
    })
    builder.addCase(getUsersList.fulfilled, (state, action) => {
        state.users = action.payload.users;
        state.filtredUsers = action.payload.users
        state.usersCount = action.payload.usersCount;
        state.isUsersLoading = false;
    })
    builder.addCase(getUsersList.rejected, (state, action) => {
      state.isUsersLoading = false;
      state.users = []
    })
    //Get Team List
    builder.addCase(getTeamsList.pending, (state) => {
      state.isTeamsLoading = true;
    })
    builder.addCase(getTeamsList.fulfilled, (state, action) => {
        state.teams = action.payload.teams;
        state.teamsCount = action.payload.teamsCount;
        state.isTeamsLoading = false;
    })
    builder.addCase(getTeamsList.rejected, (state, action) => {
      state.isTeamsLoading = false;
      state.teams = []
    })
    //Send extension
    builder.addCase(sendEmailExtension.pending, (state) => {
      state.isSendingExtension = true;
    })
    builder.addCase(sendEmailExtension.fulfilled, (state, action) => {
        state.isSendingExtension = false;
    })
    builder.addCase(sendEmailExtension.rejected, (state, action) => {
      state.isSendingExtension = false;
    })
    //Generate password
    builder.addCase(generatePassword.pending, (state) => {
      state.isPasswordGenerating = true;
    })
    builder.addCase(generatePassword.fulfilled, (state, action) => {
        state.generatedPassword = action.payload.password;
        state.isPasswordGenerating = false;
    })
    builder.addCase(generatePassword.rejected, (state, action) => {
      state.isPasswordGenerating = false;
      state.generatedPassword = null
    })
    //Send credentials
    builder.addCase(sendEmailCredentials.pending, (state) => {
      state.isSendingCredentials = true;
    })
    builder.addCase(sendEmailCredentials.fulfilled, (state, action) => {

      state.isSendingCredentials = false;
    })
    builder.addCase(sendEmailCredentials.rejected, (state, action) => {
      state.isSendingCredentials = false;
    })    
    //Update User
    builder.addCase(updateUser.pending, (state) => {
      state.isUserUpdating = true;
    })
    builder.addCase(updateUser.fulfilled, (state, action) => {
      const filtredUpdatedUser = state.filtredUsers.find(user => user.uidd === action.payload.user.uidd)
      const updatedUser = state.filtredUsers.find(user => user.uidd === action.payload.user.uidd)
      if(updatedUser.organizationId !== action.payload.user.organizationId) {
        const decreaseUserCountCompany = state.teams.find(team => team.teamsUuid === updatedUser.organizationId)
        if(decreaseUserCountCompany) {
          decreaseUserCountCompany.usersCount -= 1;
        }
        
        const increaseUserCountCompany = state.teams.find(team => team.teamsUuid === action.payload.user.organizationId)
        if(increaseUserCountCompany) {
          increaseUserCountCompany.usersCount += 1
        }        
      }
      if(updatedUser) {
        updatedUser.slots = action.payload.user.slots
        updatedUser.slotsLeft = action.payload.user.slotsLeft
        updatedUser.proxy = action.payload.user.proxy
        updatedUser.organizationName = action.payload.user.organizationName
        updatedUser.organizationId = action.payload.user.organizationId
        updatedUser.roles = action.payload.user.roles
        updatedUser.email = action.payload.user.email
        updatedUser.userleadsCount = action.payload.userleadsCount;

        filtredUpdatedUser.slots = action.payload.user.slots
        filtredUpdatedUser.slotsLeft = action.payload.user.slotsLeft
        filtredUpdatedUser.proxy = action.payload.user.proxy
        filtredUpdatedUser.organizationName = action.payload.user.organizationName
        filtredUpdatedUser.organizationId = action.payload.user.organizationId
        filtredUpdatedUser.roles = action.payload.user.roles
        filtredUpdatedUser.email = action.payload.user.email
        filtredUpdatedUser.userleadsCount = action.payload.userleadsCount

      }
      state.isUserUpdating = false;
    })
    builder.addCase(updateUser.rejected, (state, action) => {
      state.isUserUpdating = false;
    })
    //Delete User
    builder.addCase(deleteUser.pending, (state) => {
      state.isDeletingUser = true;
    })
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      if(action.payload.isDeleted) {
        state.filtredUsers = state.filtredUsers.filter(user => user.uidd !== action.meta.arg.userUuid)
        state.users = state.users.filter(user => user.uidd !== action.meta.arg.userUuid)
      }

      state.isDeletingUser = false;
    })
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.isDeletingUser = false;
    })
    //Create team
    builder.addCase(createTeam.pending, (state) => {
      state.isCreatingTeam = true;
    })
    builder.addCase(createTeam.fulfilled, (state, action) => {
      state.teams.push(action.payload.team)
      state.isCreatingTeam = false
    })
    
    builder.addCase(createTeam.rejected, (state, action) => {
      state.isCreatingTeam = false;
    })
    //Delete team
    builder.addCase(deleteTeam.pending, (state) => {
      state.isDeletingTeam = true;
    })
    builder.addCase(deleteTeam.fulfilled, (state, action) => {
      if(action.payload.isDeleted) {
        state.teams = state.teams.filter(team => team.teamsUuid !== action.meta.arg.teamsUuid)
        state.users.forEach(user => {
          if(user.organizationId === action.meta.arg.teamsUuid) {
            user.organizationName = 'none'
          }
        })
        state.filtredUsers.forEach(user => {
          if(user.organizationId === action.meta.arg.teamsUuid) {
            user.organizationName = 'none'
          }
        })
      } 
      state.isDeletingTeam = false
    })
    
    builder.addCase(deleteTeam.rejected, (state, action) => {
      state.isDeletingTeam = false;
    })
    //Update team
    builder.addCase(updateTeam.pending, (state) => {
      state.isUpdatingTeam = true;
    })
    builder.addCase(updateTeam.fulfilled, (state, action) => {
      if(action.payload.isUpdated) {
        const updatedTeam = state.teams.find(team => team.teamsUuid === action.payload.team.teamsUuid)
        if(updateTeam) {
          updatedTeam.teamName = action.payload.team.teamName
        }
        state.users.forEach(user => {
          if(user.organizationId === action.payload.team.teamsUuid) {
            user.organizationName = action.payload.team.teamName
          }
        })
        state.filtredUsers.forEach(user => {
          if(user.organizationId === action.payload.team.teamsUuid) {
            user.organizationName = action.payload.team.teamName
          }
        })
      }
      state.isUpdatingTeam = false
    })
    
    builder.addCase(updateTeam.rejected, (state, action) => {
      state.isUpdatingTeam = false;
    })
    //Repair user
    builder.addCase(deleteAdditionalWrongInfo.pending, (state, action) => {
      state.isUserRepairing = action.meta.arg.userUuid;
    })
    builder.addCase(deleteAdditionalWrongInfo.fulfilled, (state, action) => {
      state.isUserRepairing = null
    })
    
    builder.addCase(deleteAdditionalWrongInfo.rejected, (state, action) => {
      state.isUserRepairing = null
    })        
  }
});

export const adminPageSliceActions = adminPageSlice.actions;
export const adminPageReducer = adminPageSlice.reducer;