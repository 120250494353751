import * as React from 'react';
import { LeadActivityOnReply } from '../../../types/entities/leadActivityOnReply';
import { Box, Link, Typography } from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';

type Props = {
  leadActivityOnReply?: LeadActivityOnReply;
  isFilterOpen: boolean;
}
export const ReactionOnComment = ({ leadActivityOnReply, isFilterOpen }: Props) => {
  const typeOfActivity = leadActivityOnReply?.typeOfActivity;

  if (!leadActivityOnReply) {
    return (
      <Typography sx={{
        fontFamily: 'Kanit',
        fontWeight: 300,
        color: '#6f7d9b',
        display: 'flex',
        justifyContent: 'center'
      }}>
        {isFilterOpen ? '-' : 'No reactions yet'}
      </Typography>
    )
  }

  if (typeOfActivity === 'Reply') {
    return (
      <Link underline="none" href={leadActivityOnReply.linkToComment} target='_blank'>
        <Typography
          sx={{
            fontFamily: 'Kanit',
            fontWeight: 300,
            color: '#122E94'
          }}>

          {isFilterOpen
            ? (<Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '6px'
              }}
            >
              <ReplyIcon
                sx={{
                  fontSize: '22px',
                  color: '#6D30B7'
                }}
              />
            </Box>)
            : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: '6px'
                }}
              >
                <ReplyIcon
                  sx={{
                    fontSize: '22px',
                    color: '#6D30B7'
                  }}
                />
                {
                  leadActivityOnReply?.valueOfActivity.length > 30
                    ? `${leadActivityOnReply?.valueOfActivity.slice(0, 35)}...`
                    : leadActivityOnReply?.valueOfActivity
                }
              </Box>
            )}
        </Typography>
      </Link>
    )
  }

  return (
    <Link underline="none" href={leadActivityOnReply.linkToComment} target='_blank'>
      <Typography
        sx={{
          fontFamily: 'Kanit',
          fontWeight: 300,
          color: '#122E94'
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: isFilterOpen ? 'center' : 'flex-start',
            alignItems: 'center',
            gap: '12px'
          }}
        >
          <ThumbUpRoundedIcon
            sx={{
              color: '#6D30B7',
              fontSize: '18px'
            }}
          />
          {!isFilterOpen && `${typeOfActivity} your comment`}
        </Box>
      </Typography>
    </Link>

  )
}

