import { PromptV2Link } from "../../../types/pages/automationPage/promptV2Names";

export const checkIsSettingShow = (promptLink: string, generateDM: boolean, generateInviteMessage: boolean) => {
  if(promptLink === PromptV2Link.messagePrompt) {
    return !generateDM;
  }

  if(promptLink === PromptV2Link.invitePrompt) {
    return !generateInviteMessage;
  }

  return true;
}