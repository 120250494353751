export type PagesTabsType = {
  id: string;
  title: string;
  link: string,
  disabled: boolean,
  type?: TabsType,
  feedCount?: number
}[]

export  enum TabsType {
  regular = 'regular',
  feed = 'feed',
  setting = 'setting'
}