import { Box, Button } from '@mui/material';
import * as React from 'react';
import scss from './BulkMarkAsReadButton.module.scss';
import { useTableLeadsData } from '../../utils/redux/storeHooks/dataHooks/useTableLeadsData';
import { useTableLeadsActions } from '../../utils/redux/storeHooks/actionsHooks/useTableLeadsActions';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { getPageFromTo } from '../../utils/general/getPageFromTo';

export const BulkMarkAsReadButton = () => {
  const { markLeadsAsRead } = useTableLeadsActions();
  const {
    selected,
    leadToSearch,
    page,
    rowsPerPage,
    order,
    orderBy,
    dateInserted,
    dateLastCommented,
    dateLastReplied,
    filterLeadName,
    filterCompanyName,
    selectedLeadConnectionPossition,
    isHaveNewActivitySelect,
    isAllSelected
  } = useTableLeadsData()

  const onMarkAsRead = () => {
    const { pageFrom, pageTo } = getPageFromTo(page, rowsPerPage);

    markLeadsAsRead({
      selectedRows: selected,
      from: pageFrom,
      to: pageTo,
      order,
      orderBy,
      filters: {
        dateInserted,
        dateLastCommented,
        dateLastReplied,
        filterLeadName: filterLeadName || leadToSearch,
        filterCompanyName,
        selectedLeadConnectionPossition,
        isHaveNewActivitySelect
      },
      isAllSelected
    })
  }

  return (
    <Box className={scss.deleteBtnContainer}>
      <Button
        className={scss.deleteBtn}
        onClick={onMarkAsRead}
        variant='contained'
        sx={{
          boxShadow: 'none',
          ':hover': {
            boxShadow: 2
          }
        }}
        startIcon={<DoneAllIcon sx={{ width: 28, height: 28, fill: '#191F2E' }} />}
      >
        Mark as read
      </Button>
    </Box>
  )
}

