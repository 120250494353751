import { useAppSelector } from '../typedHooks';

export const useSidebarData = () => {
  const {
   replies,
   totalCountOfLeads,
   pendingReplies,
   countOfRepliedFeed,
   checkOfValidCookie,
   isDataLoading,
   checkingCookiesProcess,
   leadsLimit,
   isSideBarWide,
   profileViews
  } = useAppSelector((state) => state.sidebarReducer);

  return {
    replies,
    totalCountOfLeads,
    pendingReplies,
    countOfRepliedFeed,
    checkOfValidCookie,
    isDataLoading,
    checkingCookiesProcess,
    leadsLimit,
    isSideBarWide,
    profileViews
  };
};