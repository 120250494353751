import { useAppSelector } from '../typedHooks';

export const useLeadsNotificationData = () => {
  const {
    notifications,
    isDeletingNotifications,
    isNotificationUpdating,
    isSnackBarLoadingOpen,
    isNotificationUpdatingComplete
  } = useAppSelector((state) => state.leadsNotificationReducer);

  return {
    notifications,
    isDeletingNotifications,
    isNotificationUpdating,
    isSnackBarLoadingOpen,
    isNotificationUpdatingComplete
  };
};