import { useMemo } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useAppDispatch } from '../typedHooks';
import { newFeedActions } from '../../slices/newFeedSlice/newFeedSlice';
import { createNewTab, deletePost, deleteTab, generateMessage, getAllTabs, getFeed, getFeedCount, getFeedGeneralTab, getPromptList, sendMessage, updateTab, updateTitleTab } from '../../slices/newFeedSlice/thunks';

const rootActions = {
  ...newFeedActions,
  createNewTab,
  deleteTab,
  updateTab,
  updateTitleTab,
  getFeed,
  getAllTabs,
  getPromptList,
  generateMessage,
  sendMessage,
  deletePost,
  getFeedCount,
  getFeedGeneralTab
};

export const useNewFeedActions = () => {
  const dispatch = useAppDispatch();
  return useMemo(() => bindActionCreators(rootActions, dispatch), [dispatch]);
};