import * as React from 'react';
import { CircularProgress } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';

type Props = {
  isAutomationRunning: boolean;
  automationTabId: string;
  tabId: string;
  currentTabFeedCount: number;
  isRunAutomationRequest: boolean;
}

export const RunAutomationButtonContent = ({
  isAutomationRunning,
  automationTabId,
  tabId,
  currentTabFeedCount,
  isRunAutomationRequest
}: Props) => {
  if ((isAutomationRunning && automationTabId === tabId && currentTabFeedCount)) {
    return (
      <>
        <CircularProgress
          size={24}
          color='inherit'
          sx={{ marginRight: '5px' }}
        />
        Stop automation
      </>
    )
  }

  return (
    <>
      <SyncIcon />
      Run automation
      {isRunAutomationRequest && <CircularProgress
        size={24}
        color='inherit'
        sx={{ position: 'absolute' }}
      />}
    </>
  )
}

