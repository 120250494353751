import * as React from 'react';
import { SinglePost } from './SinglePost';
import { Box } from '@mui/material';
import style from '../../newFeedPage.module.scss'
import { useNewFeedData } from '../../../../utils/redux/storeHooks/dataHooks/useNewFeedData';
import { useParams } from 'react-router-dom';
import { useNewFeedActions } from '../../../../utils/redux/storeHooks/actionsHooks/useNewFeedActions';
import { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CustomLinearProgress } from '../../../../utils/pages/newFeedPage/CustomLinearProgress';
import { NoDataAtSearch } from '../../../../components/noDataForSearch/NoDataForSearch';

export const PostsList = () => {
  const { tabs, pagesForTabs, isGetFeedLoading } = useNewFeedData();
  const { getFeed, getFeedGeneralTab, resetCurrenPagesForTab, resetFeedForTab } = useNewFeedActions();
  const { tabId } = useParams();
  const currentTab = tabs.find(tab => String(tab.id) === tabId) || null;
  const currentPagesForTab = pagesForTabs.find(pages => pages.tabId === tabId)

  const onFeedRequestHandler = () => {
    const { page } = currentPagesForTab;

    const pageFrom = page === 0 ? 0 : page * 10;
    const pageTo = pageFrom + 10;

    if (currentTab.id === 'general') {
      getFeedGeneralTab({
        from: pageFrom,
        to: pageTo,
        includeKeywords: JSON.stringify(currentTab.includeKeywords),
        excludeKeywords: JSON.stringify(currentTab.excludeKeywords),
        daysOption: currentTab.daysOption.value,
        excludeLeadComentedBeforeDayCount: currentTab.excludeLeadComentedBeforeDayCount.value
      })
      return;
    }

    getFeed({
      from: pageFrom,
      to: pageTo,
      tabId
    })
  }

  useEffect(() => {
    if (currentTab.id === 'general') {
      resetFeedForTab('general')
      getFeedGeneralTab({
        from: 0,
        to: 10,
        includeKeywords: JSON.stringify(currentTab.includeKeywords),
        excludeKeywords: JSON.stringify(currentTab.excludeKeywords),
        daysOption: currentTab.daysOption.value,
        excludeLeadComentedBeforeDayCount: currentTab.excludeLeadComentedBeforeDayCount.value
      })
      resetCurrenPagesForTab('general')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab.daysOption, currentTab.excludeKeywords, currentTab.includeKeywords, currentTab.excludeLeadComentedBeforeDayCount])

  if (!isGetFeedLoading && currentTab.feed.length === 0) {
    return (
      <NoDataAtSearch />
    )
  }

  return (
    currentTab && (
      <InfiniteScroll
        dataLength={currentTab.feed.length}
        next={onFeedRequestHandler}
        hasMore={currentPagesForTab.hasMore}
        loader={<CustomLinearProgress />}
        endMessage={
          <p style={{ textAlign: 'center', fontWeight: 300 }}>
            - No more items for load. -
          </p>
        }
        style={{ width: '100%' }}
      >
        <Box className={style.postsWrapper}>
          {currentTab.feed.map(post => <SinglePost key={post.id} post={post} />)}
        </Box>
      </InfiniteScroll>
    )
  )
}

