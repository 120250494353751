import React from 'react';
import styles from "./DeleteTab.module.scss";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { Box, Typography } from '@mui/material';
import { MainActionButton } from '../../actionButton/MainActionButton';
import { useNewFeedData } from '../../../utils/redux/storeHooks/dataHooks/useNewFeedData';
import { useNewFeedActions } from '../../../utils/redux/storeHooks/actionsHooks/useNewFeedActions';

export const PopUpDeleteTab = () => {
  const { tabs, deleteOrUpdateTabId, isDeletingtab } = useNewFeedData();
  const { deleteTab } = useNewFeedActions();
  const { setPopUpDeleteTabOpen } = usePopupActions();
  const initialTitleValue = tabs.find(tab => tab.id === deleteOrUpdateTabId)?.title || '';

  const closePopUp = () => {
    setPopUpDeleteTabOpen(false)
  }

  const deleteCurretnTab = () => {
    deleteTab({
      tabId: deleteOrUpdateTabId,
    })
  }

  return (
    <Box className={styles.popup}>
      <Box className={styles.header}>
        <Box className={styles.header__title}>
          Remove the Campaign
        </Box>
        <IconButton
          color="default"
          onClick={closePopUp}
          className={styles.header__closeButton}
        >
          <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
        </IconButton>
      </Box>
      <Box className={styles.content}>
        <Typography className={styles.content__text}>
          Do you really want to remove the campaign?
        </Typography>
      </Box>

      <Box className={styles.footer}>
        <Button
          variant='text'
          onClick={closePopUp}
          className={styles.footer__cancel}
        >
          Cancel
        </Button>
        <MainActionButton
          title='Confirm'
          height={40}
          width={80}
          fontWeight={300}
          actionOnClick={deleteCurretnTab}
          isLoading={isDeletingtab}
          isDisabled={isDeletingtab}
        />
      </Box>
    </Box>
  );
};
