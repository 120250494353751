export const isKeywordsEqualWithInitial = (
  initialKeywords: string[],
  currentKeywords: string[]
) => {

  const set1 = new Set(initialKeywords);
  const set2 = new Set(currentKeywords);

  if (set1.size !== set2.size) {
    return false;
  }

  for (const item of set1) {
    if (!set2.has(item)) {
      return false;
    }
  }

  return true;
}