import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {Get, Post } from '../../../general/https';
import { DeleteMembersPayload, DeleteMembersResponse, GetTeamListPayload, GetTeamListResponse, TableTeamManagementSliceInitialState } from './types';
import { TeamManagementMemberData } from '../../../../types/entities/teamManagementMemberData';

export const getTeamList = createAsyncThunk<GetTeamListResponse, GetTeamListPayload>(
  'tableTeamManagementSlice/getTeamList',
  async ({userInformation}, {rejectWithValue}) => {
    try {
      const response = await Get(`/team-information/${userInformation.organizationId}`, false)
      return response
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const deleteTeamMemberAndUpdateTeamList = createAsyncThunk<DeleteMembersResponse, DeleteMembersPayload>(
  'tableTeamManagementSlice/deleteMemberAndUpdateTeamList',
  async ({selectedRowId}, {rejectWithValue}) => {
    try {
      const response = await Post(`/team-information/remove-member`, { id: selectedRowId }, true)      
      return response
    } catch(error) {
      return rejectWithValue(error.message)
    }

  }
)

const initialState: TableTeamManagementSliceInitialState = {
  rows: [],      
  loading: true,
  organizationRole: '',
  filteredTeamMembers: [],
  deletingRowId: null,
};

export const tableTeamManagementSlice = createSlice({
  name: 'tableTeamManagementSlice',
  initialState,
  reducers: {
    setRows: (state, { payload }: PayloadAction<TeamManagementMemberData[]>) => {
      state.rows = payload;
    },    
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },   
    setOrganizationRole: (state, { payload }: PayloadAction<string>) => {
      state.organizationRole = payload;
    },
    setfilteredTeamMembers: (state, { payload }: PayloadAction<TeamManagementMemberData[]>) => {
      state.filteredTeamMembers = payload;
    },
  },
  extraReducers: (builder) => {
    //Get Team List
    builder.addCase(getTeamList.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(getTeamList.fulfilled, (state, action) => {
      state.organizationRole = action.meta.arg.userInformation.organizationRole;
      state.rows = action.payload;
      state.filteredTeamMembers = action.payload;
      state.loading = false;
    })
    builder.addCase(getTeamList.rejected, (state, action) => {
      state.loading = false;
      state.rows = [];
      state.filteredTeamMembers = [];
    })
     //Delete and update Lead List
     builder.addCase(deleteTeamMemberAndUpdateTeamList.pending, (state, action) => {
      state.deletingRowId = action.meta.arg.selectedRowId
    })
    builder.addCase(deleteTeamMemberAndUpdateTeamList.fulfilled, (state, action) => {
      const deletedMemberId = action.meta.arg.selectedRowId;
      const updatedTeams = state.rows.filter(member => member.id !== deletedMemberId);
      state.rows = updatedTeams;
      state.filteredTeamMembers = updatedTeams;
      state.deletingRowId = null;
    })
    builder.addCase(deleteTeamMemberAndUpdateTeamList.rejected, (state) => {
      state.deletingRowId = null;
    })    
  }
});

export const tableTeamManagementActions = tableTeamManagementSlice.actions;
export const tableTeamManagementReducer = tableTeamManagementSlice.reducer;