import * as React from 'react';
import { Alert, AlertTitle, Slide, Snackbar } from "@mui/material";
import { useNotificationData } from '../../utils/redux/storeHooks/dataHooks/useNotificationData';
import { useNotificationActions } from '../../utils/redux/storeHooks/actionsHooks/useNotificationActions';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const AppSnackbar = () => {
  const {
    isSnackbarOpen,
    vertical,
    horizontal,
    message,
    severenity,
    alertTitle,
    duration
  } = useNotificationData()

  const {
    closeSnackBar
  } = useNotificationActions()

  const handleClose = () => {
    closeSnackBar()
  };

  const iconStyles = {
    color: '#191F2E',
    fontSize: '28px'
  }

  let icon;
  let alternativeTextMessage;
  switch (severenity) {
    case 'success':
      icon = <CheckCircleIcon sx={iconStyles} />
      alternativeTextMessage = 'action successfully completed'
      break;
    case 'error':
      icon = <CancelIcon sx={iconStyles} />
      alternativeTextMessage = 'We are sorry, somthing went wrong'
      break;
    case 'warning':
      icon = <WarningIcon sx={iconStyles} />
      alternativeTextMessage = "something didn't work correctly"
      break;
    default:
      icon = <InfoIcon sx={iconStyles} />
      alternativeTextMessage = "action completed"

  }

  return (
    <Slide direction="up" in={isSnackbarOpen}>
      <Snackbar
        open={isSnackbarOpen}
        onClose={handleClose}
        autoHideDuration={duration}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          onClose={handleClose}
          severity={severenity}
          sx={{ width: '500px', color: '#191F2E', fontSize: '18px' }}
          icon={icon}
        >
          <AlertTitle sx={{ fontSize: '20px', fontWeight: 600 }}>
            {alertTitle || severenity}
          </AlertTitle>
          {message || alternativeTextMessage}
        </Alert>
      </Snackbar>
    </Slide>
  );
}
