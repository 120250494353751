import React, { useState } from 'react';
import styles from "./ExportLeads.module.scss";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { Box, Typography } from '@mui/material';
import { MainActionButton } from '../../actionButton/MainActionButton';
import { useTableLeadsActions } from '../../../utils/redux/storeHooks/actionsHooks/useTableLeadsActions';
import { useTableLeadsData } from '../../../utils/redux/storeHooks/dataHooks/useTableLeadsData';
import LogoutIcon from '@mui/icons-material/Logout';
import { StyledFormControlCheckbox } from '../../styledFormControlCheckbox/StyledFormControlCheckbox';

export const PopUpExportLeads = () => {
  const [checkedOptions, setCheckedOptions] = useState({
    name: true,
    jobTitle: false,
    currentCompany: false,
    linkedinUrl: false,
    salesNavUrl: false,
    connectionType: false,
  });
  const { setPopupExportLeadOpen } = usePopupActions();
  const { exportLeads } = useTableLeadsActions();
  const {
    isExportingLeads,
    dateInserted,
    dateLastCommented,
    dateLastReplied,
    filterLeadName,
    filterCompanyName,
    selectedLeadConnectionPossition,
    isHaveNewActivitySelect
  } = useTableLeadsData();

  const closePopUp = () => {
    setPopupExportLeadOpen(false)
  }

  const onExportLeads = () => {
    exportLeads({
      filters: {
        dateInserted,
        dateLastCommented,
        dateLastReplied,
        filterLeadName,
        filterCompanyName,
        selectedLeadConnectionPossition,
        isHaveNewActivitySelect
      },
      selectedExportOptions: checkedOptions
    })
    console.log('export leads')
  }

  const handleChangeCheckBoxes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedOptions({
      ...checkedOptions,
      [event.target.name]: event.target.checked,
    });
  };

  const { name, jobTitle, currentCompany, linkedinUrl, salesNavUrl, connectionType } = checkedOptions;

  return (
    <Box className={styles.popup}>
      <Box className={styles.header}>
        <Box className={styles.header__title}>
          Export .csv format
        </Box>
        <IconButton
          color="default"
          onClick={closePopUp}
          className={styles.header__closeButton}
        >
          <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
        </IconButton>
      </Box>
      <Box className={styles.content}>
        <Typography className={styles.content__text}>
          Please select the parameters you wish to include in the export.
        </Typography>
        <Box className={styles.content__checkBoxes}>
          <StyledFormControlCheckbox
            isChecked={name}
            onChange={handleChangeCheckBoxes}
            checkBoxName='name'
            label='Name'
          />
          <StyledFormControlCheckbox
            isChecked={jobTitle}
            onChange={handleChangeCheckBoxes}
            checkBoxName='jobTitle'
            label='Job title'
          />
          <StyledFormControlCheckbox
            isChecked={currentCompany}
            onChange={handleChangeCheckBoxes}
            checkBoxName='currentCompany'
            label='Current company'
          />
          <StyledFormControlCheckbox
            isChecked={linkedinUrl}
            onChange={handleChangeCheckBoxes}
            checkBoxName='linkedinUrl'
            label='Linkedin URL'
          />
          <StyledFormControlCheckbox
            isChecked={salesNavUrl}
            onChange={handleChangeCheckBoxes}
            checkBoxName='salesNavUrl'
            label='Sales Nav URL'
          />
          <StyledFormControlCheckbox
            isChecked={connectionType}
            onChange={handleChangeCheckBoxes}
            checkBoxName='connectionType'
            label='Connection type'
          />
        </Box>
      </Box>

      <Box className={styles.footer}>
        <MainActionButton
          icon={<LogoutIcon sx={{ transform: 'rotate(-90deg)', fontSize: '18px' }} />}
          title='Export'
          height={40}
          width={400}
          fontWeight={300}
          actionOnClick={onExportLeads}
          isLoading={isExportingLeads}
          isDisabled={isExportingLeads}
        />
      </Box>
    </Box>
  );
};
