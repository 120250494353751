import * as React from 'react';
import { usePopupActions } from "../../utils/redux/storeHooks/actionsHooks/usePopupActions";
import { usePopupData } from "../../utils/redux/storeHooks/dataHooks/usePopupData";
import PortalPopup from "../portalPopup/PortalPopup";
import { PopupAddLead } from '../popups/add_lead/PopupAddLead';
import { PopupSendMessage } from '../popups/send_message_to_user/PopupSendMessage';
import { PopupImportCSV } from '../popups/importCSV/ImportCSV';
import { PopUpRenameTab } from '../popups/renameTab/RenameTab';
import { PopUpDeleteTab } from '../popups/deleteTab/DeleteTab';
import { PopUpAddBot } from '../popups/addBot/AddBot';
import { PopUpAddLeadUseSn } from '../popups/addLeadUseSn/PopUpAddLeadUseSn';
import { PopUpStopParsingLeads } from '../popups/stopParsingLeads/StopParsingLeads';
import { PopUpRunAutomation } from '../popups/runAutomation/RunAutomation';
import { PopUpManageUser } from '../popups/manageUser/ManageUser';
import { useAdminPageActions } from '../../utils/redux/storeHooks/actionsHooks/useAdminPageActions';
import { PopUpSendExtension } from '../popups/sendExtension/SendExtension';
import { PopUpSendCredential } from '../popups/sendCredetial/SendCredential';
import { PopUpDeleteUser } from '../popups/deleteUser/DeleteUser';
import { PopUpCreateTeam } from '../popups/createTeam/CreateTeam';
import { PopUpUpdateTeam } from '../popups/updateTeam/UpdateTeam';
import { PopUpDeleteTeam } from '../popups/deleteTeam/DeleteTeam';
import { PopUpExportLeads } from '../popups/exportLeads/ExportLeads';
import { PopUpUpgradePlan } from '../popups/upgradePlan/UpgradePlan';
import { useEffect, useRef } from 'react';

export const AppPopupsList = () => {
  const {
    setPopupAddLeadOpen,
    setPopupImportCSVOpen,
    setPopupRenameTabOpen,
    setPopUpDeleteTabOpen,
    setPopUpAddBotOpen,
    setPopUpAddLeadsUseSnOpen,
    setPopUpStopParsingLeads,
    setPopUpRunAutomation,
    setPopupManageUserOpen,
    setPopupSendExtensionOpen,
    setPopupSendCredentialOpen,
    setPopupDeleteTeamOpen,
    setPopupUpdateTeamOpen,
    setPopupDeleteUserOpen,
    setPopupCreateTeamOpen,
    setPopupExportLeadOpen,
    setPopupUpgradePlanOpen
  } = usePopupActions()
  const {
    isPopupAddLeadOpen,
    isPopupSendMessageOpen,
    isPopUpImportCSVOpen,
    isPopupRenameTabOpen,
    isPopUpDeleteTabOpen,
    isPopUpAddBotOpen,
    isPopUpAddLeadsUseSnOpen,
    isPopUpStopParsingLeadsOpen,
    isPopUpRunAutomationOpen,
    isPopUpManageUserOpen,
    isPopUpSendExtensionOpen,
    isPopUpSendCredentialOpen,
    isPopUpCreateTeamOpen,
    isPopUpUpdateTeamOpen,
    isPopUpDeleteTeamOpen,
    isPopUpDeleteUserOpen,
    isPopUpExportLeadsOpen,
    isPopUpUpgradePlanOpen
  } = usePopupData()
  const { setManageCurrentUser, resetGeneratedPassword, setManageCurrentTeam } = useAdminPageActions()


  const closeExportLead = () => {
    setPopupExportLeadOpen(false);
  };

  const closePopupAddLead = () => {
    setPopupAddLeadOpen(false);
  };

  const closePopupSendMessage = () => {
    setPopupAddLeadOpen(false);
  };

  const closePopupImportSCV = () => {
    setPopupImportCSVOpen(false);
  };

  const closePopupRenameTab = () => {
    setPopupRenameTabOpen(false);
  };

  const closePopupDeleteTab = () => {
    setPopUpDeleteTabOpen(false);
  };

  const closePopupAddBot = () => {
    setPopUpAddBotOpen(false);
  };

  const closePopUpAddLeadsUseSnOpen = () => {
    setPopUpAddLeadsUseSnOpen(false);
  };

  const closePopUpStopParsingLeads = () => {
    setPopUpStopParsingLeads(false);
  };

  const closePopUpRunAutomation = () => {
    setPopUpRunAutomation(false);
  };

  const closePopUpManageUser = () => {
    setPopupManageUserOpen(false);
    setManageCurrentUser(null)
  };

  const closePopUpSendExtensionOpen = () => {
    setPopupSendExtensionOpen(false);
  };

  const closePopUpSendCredentialsOpen = () => {
    resetGeneratedPassword()
    setPopupSendCredentialOpen(false);
  };

  const closePopUpDeleteUser = () => {
    setPopupDeleteUserOpen(false);
    setManageCurrentUser(null)
  };

  const closePopUpCreateTeam = () => {
    setPopupCreateTeamOpen(false);
  };

  const closePopUpDeleteTeam = () => {
    setManageCurrentTeam(null)
    setPopupDeleteTeamOpen(false);
  };

  const closePopUpUpdateTeam = () => {
    setManageCurrentTeam(null)
    setPopupUpdateTeamOpen(false);
  };

  const closePopUpUpgradePlan = () => {
    setPopupUpgradePlanOpen(false);
  };

  // const appContentRef = useRef<HTMLDivElement | null>(null);

  // useEffect(() => {
  //   appContentRef.current = document.getElementById("heet-app-content");
  // }, []);


  return (
    <>
      {
        isPopupAddLeadOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            onOutsideClick={closePopupAddLead}
          >
            <PopupAddLead />
          </PortalPopup>
        )
      }
      {
        isPopupSendMessageOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            onOutsideClick={closePopupSendMessage}
          >
            <PopupSendMessage />
          </PortalPopup>
        )
      }
      {
        isPopUpImportCSVOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            onOutsideClick={closePopupImportSCV}
          >
            <PopupImportCSV />
          </PortalPopup>
        )
      }
      {
        isPopupRenameTabOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            onOutsideClick={closePopupRenameTab}
          >
            <PopUpRenameTab />
          </PortalPopup>
        )
      }
      {
        isPopUpDeleteTabOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            onOutsideClick={closePopupDeleteTab}
          >
            <PopUpDeleteTab />
          </PortalPopup>
        )
      }
      {
        isPopUpAddBotOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            onOutsideClick={closePopupAddBot}
          >
            <PopUpAddBot />
          </PortalPopup>
        )
      }
      {
        isPopUpAddLeadsUseSnOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            onOutsideClick={closePopUpAddLeadsUseSnOpen}
          >
            <PopUpAddLeadUseSn />
          </PortalPopup>
        )
      }
      {
        isPopUpStopParsingLeadsOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            onOutsideClick={closePopUpStopParsingLeads}
          >
            <PopUpStopParsingLeads />
          </PortalPopup>
        )
      }
      {
        isPopUpRunAutomationOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            onOutsideClick={closePopUpRunAutomation}
          >
            <PopUpRunAutomation />
          </PortalPopup>
        )
      }
      {
        isPopUpManageUserOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            onOutsideClick={closePopUpManageUser}
          >
            <PopUpManageUser />
          </PortalPopup>
        )
      }
      {
        isPopUpSendExtensionOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            onOutsideClick={closePopUpSendExtensionOpen}
          >
            <PopUpSendExtension />
          </PortalPopup>
        )
      }
      {
        isPopUpSendCredentialOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            onOutsideClick={closePopUpSendCredentialsOpen}
          >
            <PopUpSendCredential />
          </PortalPopup>
        )
      }
      {
        isPopUpDeleteUserOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            onOutsideClick={closePopUpDeleteUser}
          >
            <PopUpDeleteUser />
          </PortalPopup>
        )
      }
      {
        isPopUpCreateTeamOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            onOutsideClick={closePopUpCreateTeam}
          >
            <PopUpCreateTeam />
          </PortalPopup>
        )
      }
      {
        isPopUpUpdateTeamOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            onOutsideClick={closePopUpUpdateTeam}
          >
            <PopUpUpdateTeam />
          </PortalPopup>
        )
      }
      {
        isPopUpDeleteTeamOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            onOutsideClick={closePopUpDeleteTeam}
          >
            <PopUpDeleteTeam />
          </PortalPopup>
        )
      }
      {
        isPopUpExportLeadsOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            onOutsideClick={closeExportLead}
          >
            <PopUpExportLeads />
          </PortalPopup>
        )
      }
      {
        isPopUpUpgradePlanOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            onOutsideClick={closePopUpUpgradePlan}
            relativeLayer={document.getElementById("heet-app-content")}
            isBlur
          >
            <PopUpUpgradePlan />
          </PortalPopup>
        )
      }
    </>
  )
}

