import * as React from 'react';
import { Avatar, Box, Button, CircularProgress, Typography } from "@mui/material";
import style from '../../botManagementPage.module.scss'
import { Bot } from '../../../../types/pages/botManagmentPage/bot';
import CookieIcon from '@mui/icons-material/Cookie';
import EngineeringIcon from '@mui/icons-material/Engineering';
import HotelIcon from '@mui/icons-material/Hotel';
import ClearIcon from '@mui/icons-material/Clear';
import { DropDownMenu } from './dropDownMenu';
import { useBotManagementActions } from '../../../../utils/redux/storeHooks/actionsHooks/botManagementActions';
import { useBotManagementData } from '../../../../utils/redux/storeHooks/dataHooks/useBotManagementData';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
type Props = {
  bot: Bot;
}
export const BotCard = ({ bot }: Props) => {
  const isCookieValid = bot.JSESSIONID !== 'ajax:unvalid'
  const { deleteUserFromBots } = useBotManagementActions();
  const { userUuidDeleteBot } = useBotManagementData();
  const isUserDeleting = userUuidDeleteBot === bot.uidd

  const onDeleteHandler = () => {
    deleteUserFromBots({ userUuid: bot.uidd })
  }

  return (
    <Box className={style.botManagement__bots_item}>
      <Avatar
        alt=""
        src={bot.profileImg}
        className={style.botManagement__bots_img}
        sx={{ width: '48px', height: '48px' }}
      />
      <Typography className={style.botManagement__bots_name}>
        {`${bot.firstName} ${bot.lastName}`}
      </Typography>
      <Box className={style.botManagement__bots_validCookie}>
        <CookieIcon sx={{ color: isCookieValid ? '#BFE8B1' : '#FFB7A3' }} />
      </Box>
      <Box className={style.botManagement__bots_isBusy}>
        {bot.isBusyLikeBot
          ? <EngineeringIcon sx={{ color: '#FFB7A3' }} />
          : (<HotelIcon sx={{ color: '#BFE8B1' }} />)}
      </Box>
      <Box className={style.botManagement__bots_isBusy}>
        {bot.premiumSubscriber
          ? <WorkspacePremiumIcon sx={{ color: '#BFE8B1', fontSize: 32 }} />
          : (<DoNotDisturbIcon sx={{ color: '#db491f' }} />)}
      </Box>
      <Box className={style.botManagement__bots_workType}>
        {bot.botCronJobName ? bot.botCronJobName : 'free'}
      </Box>
      <DropDownMenu bot={bot} />
      <Button
        className={style.botManagement__bots_deleteBot}
        onClick={onDeleteHandler}
        disabled={bot.isBusyLikeBot || isUserDeleting}
      >
        {isUserDeleting
          ? <CircularProgress sx={{ color: '#3B2470' }} size={28} />
          : <ClearIcon sx={{ color: '#3B2470' }} />
        }
      </Button>
    </Box>
  )
}

