import * as React from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import style from '../../newFeedPage.module.scss'
import { Button, CircularProgress } from '@mui/material';
import { useNewFeedActions } from '../../../../utils/redux/storeHooks/actionsHooks/useNewFeedActions';
import { useNewFeedData } from '../../../../utils/redux/storeHooks/dataHooks/useNewFeedData';

type Props = {
  postId: number;
}
export const PostDelete = ({ postId }: Props) => {
  const { deletePost } = useNewFeedActions()
  const { postIdsDeliting } = useNewFeedData();
  const handleDeletePost = () => {
    deletePost({ postId })
  }
  const isLoading = postIdsDeliting.includes(postId)
  return (
    <Button className={style.post__delete} onClick={handleDeletePost} disabled={isLoading}>
      {isLoading
        ? <CircularProgress sx={{ color: '#3B2470' }} size={24} />
        : <DeleteOutlineIcon sx={{ color: '#6F7D9B' }} />
      }
    </Button>
  )
}