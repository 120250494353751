import * as React from 'react';
import Header from '../../components/header/Header';
import style from './botManagementPage.module.scss'
import { Box } from '@mui/material';
import { BotsList } from './features/botsList/botsList';
import { Actions } from './features/actions/actions';
import { BotStatistic } from './features/botStatistic/botStatistic';
import { SnLinks } from './features/snLinks/snLinks';

export const BotManagementPage = () => {
  return (
    <>
      <Header />
      <Box className={style.botManagement__mainContainer}>
        <BotsList />
        <Actions />
        <BotStatistic />
        <SnLinks />
      </Box>
    </>
  )
}

