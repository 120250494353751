import React, { useEffect } from "react";
import { FunctionComponent } from "react";
import styles from "./DashboardLeadsPeoplePage.module.scss";
import { useTableLeadsData } from "../../utils/redux/storeHooks/dataHooks/useTableLeadsData";
import { useTableLeadsActions } from "../../utils/redux/storeHooks/actionsHooks/useTableLeadsActions";
import { PagesTabs } from "../../components/pagesTabs/PagesTabs";
import { Box } from "@mui/material";
import { pagesTabs } from "../../utils/pages/dashboardLeadsPeoplePage/tabs";
import { Outlet } from "react-router-dom";
import { DashboardLeadsPeopleActions } from "./features/DashboardLeadsPeopleActions";
import { TabsType } from "../../types/general/pagesTabs";
import { useLeadsNotificationData } from "../../utils/redux/storeHooks/dataHooks/useLeadsNotificationData";
import { LeadsFilter } from "./features/LeadsFilters";
import { AnimateFilter } from "../../components/animateFilter/AnimateFilter";
import { AnimatePresence } from "framer-motion";
import { getPageFromTo } from "../../utils/general/getPageFromTo";

export const DashboardLeadsPeoplePage: FunctionComponent = () => {
  const {
    page,
    rowsPerPage,
    leadToSearch,
    order,
    orderBy,
    dateInserted,
    isFilterOpen,
    dateLastCommented,
    dateLastReplied,
    filterCompanyName,
    selectedLeadConnectionPossition,
    filterLeadName,
    isNeedToReloadLeadsByApplyingFilter,
    isHaveNewActivitySelect
  } = useTableLeadsData();
  const { getLeadList } = useTableLeadsActions();
  const { isNotificationUpdatingComplete } = useLeadsNotificationData();

  useEffect(() => {
    // getLeadList({ page, rowsPerPage, leadToSearch, order, orderBy });
    const { pageFrom, pageTo } = getPageFromTo(page, rowsPerPage);

    getLeadList({
      from: pageFrom,
      to: pageTo,
      order,
      orderBy,
      filters: {
        dateInserted,
        dateLastCommented,
        dateLastReplied,
        filterLeadName: filterLeadName || leadToSearch,
        filterCompanyName,
        selectedLeadConnectionPossition,
        isHaveNewActivitySelect
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, getLeadList, leadToSearch, order, orderBy, isNotificationUpdatingComplete, isNeedToReloadLeadsByApplyingFilter]);

  return (
    <Box className={styles.pageContainerWithFilters}>
      <Box className={`${styles.pageContainer} ${isFilterOpen ? styles.pageContainerWidthFilterOpen : styles.pageContainerWidth}`}>
        <PagesTabs tabs={pagesTabs} tabsType={TabsType.regular} />
        <DashboardLeadsPeopleActions />
        <Outlet />
      </Box>
      <AnimatePresence>
        {isFilterOpen && (<AnimateFilter>
          <LeadsFilter />
        </AnimateFilter>)}
      </AnimatePresence>
    </Box>
  );
};
