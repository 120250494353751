import * as React from 'react';
import scss from '../../AutomationPage.module.scss'
import Box from '@mui/material/Box';
import { useAutomationData } from '../../../../utils/redux/storeHooks/dataHooks/useAutomationData';
import { useAutomationActions } from '../../../../utils/redux/storeHooks/actionsHooks/useAutomationActions';
import { NameOfSlider } from '../../../../types/pages/automationPage/NameOfSlider';
import { Actions } from './Actions';
import { CommentsLeft } from './CommentsLeft';
import { Settings } from './Settings';

export default function AutomationSettings() {
  const {
    delayBetweenComments,
    scrollStep,
    getAutomationDataloading,
    limitOfReplyForADay
  } = useAutomationData();
  const {
    setDelayBetweenComments,
    setDailyCommentsQouta,
    setLimitOfReplyForADay
  } = useAutomationActions();

  const handleChangeSlider = (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
    name: string
  ) => {
    switch (name) {
      case NameOfSlider.delayBetweenComments:
        if (Array.isArray(newValue)) {
          if (activeThumb === 0) {
            setDelayBetweenComments([Math.min(newValue[0], delayBetweenComments[1] - scrollStep), delayBetweenComments[1]]);
          } else {
            setDelayBetweenComments([delayBetweenComments[0], Math.max(newValue[1], delayBetweenComments[0] + scrollStep)]);
          }
        }
        return;
      case NameOfSlider.dailyCommentsQuota:
        if (typeof newValue === 'number') {
          setDailyCommentsQouta(newValue);
          setLimitOfReplyForADay(limitOfReplyForADay)
        }
        return;
    }
  };

  if (getAutomationDataloading) {
    return (
      <Box className={scss.automationSettingsWrapper}>
        {/* <LinearProgress color='inherit' sx={{ width: '100%' }} /> */}
      </Box>
    )
  }

  return (
    <Box className={scss.automationSettingsWrapper}>
      {/* <ExcludedLeads /> */}
      <CommentsLeft />
      <Settings handleChangeSlider={handleChangeSlider} />
      <Actions />
    </Box>
  );
}
