import { Box, Button, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAutomationActions } from '../../utils/redux/storeHooks/actionsHooks/useAutomationActions';
import { useAutomationData } from '../../utils/redux/storeHooks/dataHooks/useAutomationData';
import { CommentsCounter } from './features/CommentsCounter';

export const AutomationIndicator = () => {
  const [isHovered, setIsHovered] = useState(false);
  const { getAutomationConfig, getLeadsToExclude } = useAutomationActions();
  const {
    isAutomationRunning,
    automationTab,
    inputName,
    currentPage,
    currentNumberOfReplyForToday,
    limitOfReplyForADay
  } = useAutomationData();
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    if (automationTab) {
      navigate(automationTab.link)
    }
  }

  const onLeadsRequestHandler = () => {
    const pageFrom = currentPage === 0 ? currentPage : currentPage * 10;
    const pageTo = pageFrom + 10;

    getLeadsToExclude({
      from: pageFrom,
      to: pageTo,
      name: inputName
    })
    return;
  }

  useEffect(() => {
    getAutomationConfig()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    onLeadsRequestHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputName])

  // if (getAutomationDataloading) {
  //   return (
  //     <CircularProgress color='inherit' size={22} />
  //   )
  // }

  if (isAutomationRunning) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#E0E8F9',
            color: '#3B2470',
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            padding: '8px 12px',
            fontFamily: 'Kanit',
            boxShadow: 'none',
            borderRadius: '1px',
            '&:hover': {
              backgroundColor: '#E0E8F9',
            }
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleClick}
        >
          {isHovered && <CircularProgress color='inherit' size={22} />}
          Automation is running on {automationTab.title}
        </Button>
        <CommentsCounter
          currentNumberOfReplyForToday={currentNumberOfReplyForToday}
          limitOfReplyForADay={limitOfReplyForADay}
        />
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
      <CommentsCounter
        currentNumberOfReplyForToday={currentNumberOfReplyForToday}
        limitOfReplyForADay={limitOfReplyForADay}
      />
    </Box>
  )
}

