import { useMemo } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useAppDispatch } from '../typedHooks';
import { notificationSliceActions } from '../../slices/notificationSlice/notificationSlice';

const rootActions = {
  ...notificationSliceActions,
};

export const useNotificationActions = () => {
  const dispatch = useAppDispatch();
  return useMemo(() => bindActionCreators(rootActions, dispatch), [dispatch]);
};