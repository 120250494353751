import axios from "axios";
import React, { useState } from "react";
import { FunctionComponent } from "react";
import scss from './signIn.module.scss';
import { Alert, Button, IconButton, InputAdornment, TextField, styled } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import setUserInformationTolocalStorage from "../../../utils/general/get_set_user_information";

const CssTextField = styled(TextField)({
    '.MuiInputBase-input:-internal-autofill-selected': {
        backgroundColor: 'red',
    },
    '& label': {
        color: '#FFFFFF',
    },
    '& label.Mui-focused': {
        color: '#FFFFFF',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#FFFFFF',
    },
    '& .MuiOutlinedInput-root': {
        height: '40px',
        width: '400px',
        '& fieldset': {
            borderColor: '#FFFFFF',
            borderRadius: '2px'
        },
        '&:hover fieldset': {
            borderColor: '#FFFFFF',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#FFFFFF',
        },
        borderRadius: '2px',
    },
    '& .MuiOutlinedInput-input': {
        padding: '12px 14px', // adjust the space around the input text
        color: '#FFFFFF'
    },
    '& .MuiInputBase-input': {
        height: '40px', // set the height value as per your requirement
    },
});

const SignIn: FunctionComponent = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [badSignIn, setBadSignIn] = useState('');
    const [forgotPassword, setForgotPassword] = useState(false);
    const [emailSendMessage, setEmailSendMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false)

    const handleEmailChange = (event: any) => {
        setEmail(event.target.value);
        setIsEmailValid(true);
    };

    const handlePasswordChange = (event: any) => {
        setPassword(event.target.value);
    };

    const handleFormSubmit = (event: any) => {
        event.preventDefault();
        setLoadingSubmit(true)
        if (!isValidEmail(email) && !forgotPassword) {
            setIsEmailValid(false);
            return;
        }

        if (!forgotPassword) {
            const body = {
                email: email,
                password: password
            }
            axios.post(`${process.env.REACT_APP_API_URL}/auth/signIn`, body).then(res => {
                setUserInformationTolocalStorage(res.data);
                window.location.href = '/leads'
            }).catch(e => {
                setBadSignIn(e.response.data.message)
            }).finally(() => {
                setLoadingSubmit(false)
            })
        } else {
            const body = {
                email: email
            }
            console.log('email: ', email)
            //dispatch(setEmail(email));
            axios.post(`${process.env.REACT_APP_API_URL}/auth/restore-pass`, body).then(res => {
                setOpen(true)
                setEmailSendMessage('We send you email with link to confirm password restore')
                setTimeout(() => {
                    setOpen(false);
                }, 4000);
                // window.location.href = '/restore-pass'
            }).catch(e => {
                setBadSignIn(e.response.data.message)
            }).finally(() => {
                setLoadingSubmit(false)
            })
        }
    };

    const isValidEmail = (email: string) => {
        const emailPattern = /\S+@\S+\.\S+/;
        return emailPattern.test(email);
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const hadleForgotPassword = () => {
        setForgotPassword(true);
    };

    return (
        <div className={scss.mainContainer}>
            <div className={scss.leftSide}>
                <div className={scss.contentWrapper}>
                    <div>
                        <section className={scss.logoSection}>
                            <img className={scss.logoPadding} src={'./registrationLogo.svg'} alt="" />
                            <img src={'./registrationText.svg'} alt="" />
                        </section>

                        <section className={scss.additionalInfo}>
                            AI-powered platform to build and manage client relationships at scale  <br />
                            {/* 10 leads activity monitoring in one place */}
                        </section>
                    </div>
                    <div>
                        <form onSubmit={handleFormSubmit}>
                            <div className={scss.actionBtns}>
                                <div className={scss.labelWithInputsWrapper}>
                                    <label className={scss.inputLabels}>Email Address</label>
                                    <CssTextField
                                        // label="Email"
                                        value={email}
                                        onChange={handleEmailChange}
                                        error={!isEmailValid || badSignIn !== ''}
                                        helperText={!isEmailValid
                                            ? 'Please enter a valid email address'
                                            : badSignIn !== ''
                                                ? badSignIn
                                                : ''}
                                    />
                                </div>
                                <div className={scss.labelWithInputsWrapper}>
                                    {!forgotPassword && (<>
                                        <label className={scss.inputLabels}>Password</label>
                                        <CssTextField
                                            type={showPassword ? "text" : "password"}
                                            // label="Password"
                                            value={password}
                                            onChange={handlePasswordChange}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={handleTogglePasswordVisibility}>
                                                            {showPassword
                                                                ? <Visibility sx={{ color: '#FFFFFF' }} />
                                                                : <VisibilityOff sx={{ color: '#FFFFFF' }} />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </>)}

                                    < LoadingButton
                                        loading={loadingSubmit}
                                        loadingPosition="center"
                                        loadingIndicator={<CircularProgress color='info' size={24} />}
                                        // onClick={addLead}
                                        type="submit"
                                        className={scss.signUp}
                                    >
                                        {!forgotPassword && (<>Sign In</>)}
                                        {forgotPassword && (<>Restore</>)}
                                        {!loadingSubmit && (<div style={{ display: 'flex', backgroundColor: 'red' }}>
                                        </div>)}
                                    </LoadingButton>

                                    {/* <Button variant="contained" type="submit" className={scss.signUp}>
                                        {!forgotPassword && (<>Sign In</>)}
                                        {forgotPassword && (<>Restore</>)}
                                    </Button> */}
                                    <label className={scss.labelRestore}>
                                        Forgot password? <Button variant="text" className={scss.restoteBtn} onClick={hadleForgotPassword}>Restore</Button>
                                    </label>
                                    {/* <label className={scss.labelLogin}>
                                        Not a member? <a href="/signUp">Sign Up</a>
                                    </label> */}
                                </div>
                            </div>
                        </form>
                    </div>
                    <footer className={scss.footerInfo}>
                        <label>
                            <p>OpenAI-powered app</p>
                        </label>
                    </footer>
                </div>
            </div >


            <div className={scss.rightSide}>
                <img src={'./banner.png'} alt="" />
            </div>

            {open && (
                <div className={scss.alertAddLeadsPopup}>
                    <Alert icon={false} sx={{
                        backgroundColor: "#BFE8B1",
                        position: 'absolute',
                        bottom: '30px',
                        width: '400px'
                    }}>
                        <div>
                            <CheckCircleRoundedIcon fontSize="inherit" sx={{
                                fill: '#191F2E',
                                marginRight: '12px',
                            }} />{emailSendMessage}</div>
                        {/* <div>{emailSendMessage}</div> */}
                    </Alert>
                </div>
            )}
        </div >
    );
}
export default SignIn;
